import React from 'react';
import { Card, CardContent, makeStyles, Box, Typography } from '@material-ui/core';
import { AV_LOGO } from '../../constants/image-paths';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        overflow: 'auto'
    },
    logo: {
        width: 32,
        userSelect: 'none'
    }
}));

export default function Layout({ title, children }) {

    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Card>
                <CardContent>
                    <Box display='flex' flexDirection='row' alignItems='center' m={2} gridGap={8}>
                        <img src={AV_LOGO} className={classes.logo} alt='Asset Vue' />
                        <Typography variant='h5' color='primary'>
                            {title}
                        </Typography>
                    </Box>
                    {children}
                </CardContent>
            </Card>
        </div>
    );
}