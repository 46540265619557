import { FormHelperText } from '@material-ui/core';

import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Formik } from 'formik';
import { useAuth } from './use-auth';

import Layout from './Layout';
import TextField from './TextField';
import AuthForm from './AuthFormContainer';
import SwitchAuthLink from './SwitchAuthLink';
import SubmitButton from './SubmitButton';
import useOpenClose from '../common/use-open-close';
import ConfirmationDialog from '../common/ConfirmationDialog';

const ResetPassword = () => {

    const { t } = useTranslation();

    const { sendPasswordResetEmail } = useAuth();
    const history = useHistory();
    const mailSentToggle = useOpenClose();

    const validate = values => {
        const errors = {};

        if (!values.userName) {
            errors.userName = t('ResetPassword.UserNameRequired');
        }

        return errors;
    };

    const onSubmit = async (values, { setSubmitting, setErrors }) => {
        try {
            let success = await sendPasswordResetEmail(values.userName);
            if (success && success === true) {
                mailSentToggle.handleClickOpen();
            } else {
                return history.push('./Login');
            }
        }
        catch {
            setErrors({ submitError: t('UnknownError') })
        }
        setSubmitting(false);
    }

    const gotoLoginPage = async () => {
        return history.push('./Login');
    }

    return (
        <Layout title={t('ResetPassword')}>
            <Formik
                validate={validate}
                onSubmit={onSubmit}
                validateOnBlur={false}
                initialValues={initialValues}
            >
                {({ errors, isSubmitting }) => (
                    <AuthForm>
                        <TextField name='userName' label={t('Username')} autoFocus />

                        <div>
                            <SubmitButton isSubmitting={isSubmitting}>{t('ResetPassword')}</SubmitButton>
                            {!!errors.submitError &&
                                <FormHelperText error>{errors.submitError}</FormHelperText>
                            }
                        </div>
                    </AuthForm>
                )}
            </Formik>
            <SwitchAuthLink
                to='/login'
                linkLabel={t('Login')}
            />
            <ConfirmationDialog
                open={mailSentToggle.open}
                title={t('ResetPassword.MailSent')}
                text={t('ResetPassword.LookForEmail')}
                onClose={mailSentToggle.handleClose}
                onCancel={gotoLoginPage}
            />
        </Layout>

    );
}

export default ResetPassword;

const initialValues = {
    userName: ''
}