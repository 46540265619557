import React from 'react';
import { Box, IconButton, Paper, Typography } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';

export default function DialogTitleWithBack({ id, onBackPressed, children }) {
    return (
        <Box
            id={id}
            display='flex'
            alignItems='center'
            component={Paper}
            pr={3}
            square
        >
            <IconButton onClick={onBackPressed}><ArrowBack /></IconButton>
            <Typography variant='h6' component='span'>{children}</Typography>
        </Box>
    );
}