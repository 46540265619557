import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth as useOidcAuth } from 'react-oidc-context';
import { useHistory } from 'react-router-dom';
import SplashScreen from '../bootstrap/SplashScreen';
import CenterBox from '../common/CenterBox';
import ErrorWithRetry from '../common/ErrorWithRetry';
import SwitchAuthLink from './SwitchAuthLink';
import { useAuth } from './use-auth';
import { useConfig } from '../bootstrap/ConfigProvider';

export default function LoginWithOidcToken() {
    const { t } = useTranslation();
    const { isAuthenticated, user } = useOidcAuth();
    const [error, setError] = useState(false);
    const { signInWithOidcToken } = useAuth();
    const { oidc } = useConfig();
    const history = useHistory();

    const login = useCallback(async () => {
        setError(false);

        try {
            if (user) {
                const token_type = oidc.audience ? 'access_token' : 'id_token';
                const success = await signInWithOidcToken(user[token_type]);
                setError(!success);

                if (success) {
                    history.replace('/dashboard');
                }
            } else {
                setError(true);
            }
        } catch (error) {
            setError(true);
        }
    }, [user, oidc.audience, signInWithOidcToken, history]);

    useEffect(() => {
        if (isAuthenticated) {
            login();
        }
    }, [isAuthenticated, login]);

    return error ? (
        <CenterBox height='100%' flexDirection='column'>
            <ErrorWithRetry onClick={login} />
            <SwitchAuthLink to='/logout' linkLabel={t('BackToLogin')} />
        </CenterBox>
    ) : (
        <SplashScreen />
    );
}
