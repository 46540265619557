import { Redirect, Route } from 'react-router-dom';
import { useConfig } from '../bootstrap/ConfigProvider';
import AccessDenied from './AccessDenied';
import { useAuth } from './use-auth';

function PrivateRoute({ children, roles = [], ...rest }) {
    const { isAuthenticated, user } = useAuth();
    const { requiresSingleTenantSignUp } = useConfig();

    return (
        <Route
            {...rest}
            render={({ location }) => {
                if (!isAuthenticated) {
                    return (
                        <Redirect
                            to={{
                                pathname: requiresSingleTenantSignUp
                                    ? '/CreateNewAccount'
                                    : '/login',
                                state: { from: location }
                            }}
                        />
                    );
                }

                const isAuthorizedRole = !roles.length || roles.includes(user.roleName);

                return isAuthorizedRole ? children : <AccessDenied />;
            }}
        />
    );
}

export default PrivateRoute;
