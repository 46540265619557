import React, { useCallback, useState } from 'react';
import { Box, Divider, List, Typography, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import useScanner from './use-scanner';
import ScannedTag from './ScannedTag';
import CircleButton from './CircleButton';
import ErrorWithRetry from '../common/ErrorWithRetry';
import CenterBox from '../common/CenterBox';
import useScanSession from './use-scan-session';
import { useLocalStorage } from '../common/use-storage';
import HideUnknownTags from './HideUnknownTags';

export default function QuickScan({ onStop }) {
    const { t } = useTranslation();
    const classes = useStyles();

    const { createSession, scanSessionId, error, loading } = useScanSession();

    const [hideUnknownTags, setHideUnknownTags] = useLocalStorage(
        'quick-scan-hide-unknown-tags',
        false
    );
    const [tags, setTags] = useState([]);
    const [assetsCount, setAssetsCount] = useState(0);

    const tagReadHandler = useCallback(
        tag => setTags(s => (s.includes(tag) ? s : [tag, ...s])),
        []
    );

    useScanner(tagReadHandler);

    const history = useHistory();

    const handleStop = useCallback(() => {
        if (typeof onStop === 'function') onStop();
        history.push(`/quickscanresults/${scanSessionId}`);
    }, [history, onStop, scanSessionId]);

    const handleScanSaved = useCallback(asset => setAssetsCount(s => (asset?.id ? s + 1 : s)), []);

    return (
        <Box
            width='100vw'
            maxWidth={260}
            maxHeight='90vh'
            //minHeight={420}
            overflow='auto'
            bgcolor='background.default'
            display='flex'
            flexDirection='column'
        >
            {error ? (
                <CenterBox height={200}>
                    <ErrorWithRetry onClick={createSession} />
                </CenterBox>
            ) : (
                <CircleButton label={t('Scan.Stop')} wait={loading} onClick={handleStop} />
            )}

            <Box className={classes.container}>
                <Box px={2}>
                    <Typography variant='body1' align='center' gutterBottom>
                        {hideUnknownTags
                            ? t('Scan.AssetsFound', { count: assetsCount })
                            : t('Scan.TagsFound', { count: tags.length })}
                    </Typography>
                    <Typography variant='caption' color='textSecondary' gutterBottom>
                        {t('Scan.StopPrompt')}
                    </Typography>
                </Box>
                <Divider light />
                <HideUnknownTags
                    checked={hideUnknownTags}
                    onChange={() => setHideUnknownTags(s => !s)}
                />
                <Divider light />

                <List dense className={classes.list}>
                    {!!scanSessionId &&
                        tags.map(x => (
                            <ScannedTag
                                key={x}
                                tag={x}
                                sessionId={scanSessionId}
                                hiddenIfUnknown={hideUnknownTags}
                                onSaved={handleScanSaved}
                            />
                        ))}
                </List>
            </Box>
        </Box>
    );
}

const useStyles = makeStyles(({ palette, spacing, shadows }) => ({
    container: {
        marginTop: spacing(2),
        paddingTop: spacing(1),
        borderTopLeftRadius: 16,
        borderTopRightRadius: 16,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        background: palette.background.paper,
        boxShadow: shadows[1],
        flex: 1
    },
    list: {
        overflow: 'auto',
        paddingBottom: spacing(2)
    }
}));
