import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Form } from 'formik';

export default function AuthFormContainer(props) {

    const classes = useStyles();

    return <Form className={classes.form} {...props} />
}

const useStyles = makeStyles(theme => ({
    form: {
        minWidth: 300,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        gap: theme.spacing(4),
        padding: theme.spacing(2)
    }
}));