import React from 'react';
import { Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import DialogTitleWithBack from './DialogTitleWithBack';

export default function ConfirmationDialog({ open, onClose, text, title, onCancel, onSuccess }) {

    const { t } = useTranslation();

    return (

        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
        >
            <DialogTitleWithBack id='alert-dialog-title' onBackPressed={onClose}>
                {title}
            </DialogTitleWithBack>
            <DialogContent>
                <DialogContentText id='alert-dialog-description'>
                    {text}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                {typeof onSuccess === 'function' &&
                    <Button onClick={onSuccess} variant='contained' color='primary'>
                        {t('Yes')}
                    </Button>
                }
                <Button variant='contained' onClick={onCancel} action={setFocus}>
                    {typeof onSuccess === 'function' ? t('No') : t('Ok')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

const setFocus = action => action?.focusVisible()

ConfirmationDialog.propTypes = {
    onCancel: PropTypes.func.isRequired,
    onSuccess: PropTypes.func,
};