import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

export default function useScanner(onTagRead) {

    const tag = useRef('');

    useEffect(
        () => {

            const handler = e => {

                if (e.key.length === 1) {
                    tag.current += e.key;
                }
                else if (e.key === 'Enter') {

                    if (tag.current) {
                        onTagRead(tag.current.toUpperCase());
                    }

                    tag.current = '';
                }
            }

            document.addEventListener('keyup', handler);

            return () => document.removeEventListener('keyup', handler);
        },
        [onTagRead]
    );
}

useScanner.propTypes = {
    onTagRead: PropTypes.func.isRequired
}