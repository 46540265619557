import { InMemoryCache } from '@apollo/client';
import { relayStylePagination } from '@apollo/client/utilities';

export const getCache = () =>
    new InMemoryCache({
        typePolicies: {
            Query: {
                fields: {
                    folders: relayStylePagination(['where']),
                    models: relayStylePagination(['defaultClass', 'where', 'orderBy']),
                    assetTypes: relayStylePagination(['where', 'orderBy']),
                    manufacturers: relayStylePagination(['where']),
                    scanners: relayStylePagination(['where']),
                    assets: relayStylePagination(['where']),
                    directories: relayStylePagination(['where', 'orderBy']),
                    accounts: relayStylePagination(['where']),
                    assetHistories: relayStylePagination(['id']),
                    importLogs: relayStylePagination(['where', 'orderBy']),
                    importLogEntries: relayStylePagination(['where', 'orderBy'])
                }
            },
            AssetClassGraph: { keyFields: ['assetClass'] }
        }
    });
