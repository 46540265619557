import {
    AUTH_URL,
    AD_AUTH_URL,
    ACCOUNT_URL,
    RESET_PASSWORD_URL,
    CHANGE_PASSWORD_URL,
    NEW_PASSWORD_URL,
    OIDC_AUTH_URL
} from '../constants/common';

class AuthApi {
    signInWithEmailAndPassword = async (email, password) => {
        let formdata = new FormData();

        formdata.append('UserName', email);
        formdata.append('Password', password);

        return fetch(AUTH_URL, {
            method: 'POST',
            headers: {
                Accept: 'application/json'
            },
            body: formdata
        }).then(response => response.json());
    };

    signInWithADToken = async accessToken => {
        return fetch(AD_AUTH_URL, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                authorization: `Bearer ${accessToken}`
            }
        }).then(response => response.json());
    };

    signInWithOidcToken = async idToken => {
        return fetch(OIDC_AUTH_URL, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                authorization: `Bearer ${idToken}`
            }
        }).then(response => response.json());
    };

    sendPasswordResetEmail = async userName => {
        let formdata = new FormData();

        formdata.append('UserName', userName);

        return fetch(RESET_PASSWORD_URL, {
            method: 'POST',
            headers: {
                Accept: 'application/json'
            },
            body: formdata
        }).then(response => response.ok);
    };

    confirmPasswordReset = async (userName, password, token) => {
        let formdata = new FormData();

        formdata.append('UserName', userName);
        formdata.append('Password', password);
        formdata.append('Token', token);

        return fetch(NEW_PASSWORD_URL, {
            method: 'POST',
            headers: {
                Accept: 'application/json'
            },
            body: formdata
        }).then(response => response.ok);
    };

    changePassword = async (userName, password, currentPassword) => {
        let formdata = new FormData();

        formdata.append('UserName', userName);
        formdata.append('Password', password);
        formdata.append('CurrentPassword', currentPassword);

        return fetch(CHANGE_PASSWORD_URL, {
            method: 'POST',
            headers: {
                Accept: 'application/json'
            },
            body: formdata
        }).then(response => response.ok);
    };

    createNewAccount = async (company, firstName, lastName, email, phone) => {
        let formdata = new FormData();

        formdata.append('AccountName', company);
        formdata.append('FirstName', firstName);
        formdata.append('LastName', lastName);
        formdata.append('Email', email);
        formdata.append('Phone', phone);

        return fetch(ACCOUNT_URL, {
            method: 'POST',
            headers: {
                Accept: 'application/json'
            },
            body: formdata
        }).then(response => (response.ok ? true : response.json()));
    };
}

const authApi = new AuthApi();

export default authApi;
