import React from 'react';
import { FormControlLabel, Switch, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

export default function HideUnknownTags({ checked, onChange }) {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <FormControlLabel
            control={<Switch checked={checked} onChange={onChange} color='primary' size='small' />}
            label={t('Scan.HideUnknownTags')}
            color='text.secondary'
            classes={{ label: classes.label }}
            className={classes.root}
        />
    );
}

const useStyles = makeStyles(theme => ({
    root: {
        margin: '0 auto',
        padding: theme.spacing(0.5, 1)
    },
    label: {
        fontSize: theme.typography.body2.fontSize
    }
}));
