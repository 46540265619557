const FOLDER = `${process.env.PUBLIC_URL}/images/folder.png`;
const FOLDER40 = `${process.env.PUBLIC_URL}/images/folder40.png`;
const AV_LOGO = `${process.env.PUBLIC_URL}/images/avlogo.svg`;
const AV_LOGO192 = `${process.env.PUBLIC_URL}/logo-192x192.png`;
const AV_LOGO_NAMED = `${process.env.PUBLIC_URL}/images/avlogo-named.svg`;

export {
    AV_LOGO,
    AV_LOGO192,
    AV_LOGO_NAMED,
    FOLDER,
    FOLDER40,
}