const english = {
    // common
    "Add": "Add",
    "AddNew": "Add New",
    "All": "All",
    "Assets": "Assets",
    "Cancel": "Cancel",
    "Clear": "Clear",
    "Clone": "Clone",
    "Close": "Close",
    "Create": "Create",
    "DefaultError": "An error occurred",
    "Delete": "Delete",
    "Edit": "Edit",
    "Email": "Email",
    "Empty": "Empty",
    "Id": "Id",
    "Loading": "Loading...",
    "LoadMore": "Load More",
    "Login": "Login",
    "BackToLogin": "Back to login",
    "Move": "Move",
    "Name": "Name",
    "No": "No",
    "None": "None",
    "NoOptions": "No matching options",
    "Open": "Open",
    "Password": "Password",
    "Profile": "Profile",
    "Refresh": "Refresh",
    "Retry": "Retry",
    "Save": "Save",
    "SaveAndClone": "Save and Clone",
    "Select": "Select",
    "SelectAll": "Select All",
    "Search": "Search",
    "SignOut": "Sign out",
    "Submit": "Submit",
    "UnknownError": "Something went wrong",
    "Username": "Username",
    "Yes": "Yes",
    "Ok": "Ok",
    "Confirm": "Confirm",
    "Continue": "Continue",
    "Error": "Error",
    "ToggleMenu": "Toggle Menu",
    "Upload": "Upload",
    "WedgeScannerUsePrompt": "You can use wedge scanner here",

    "SignUp": "Sign Up",
    "CreateNewAccount": "Create New Account",
    "FirstName": "First Name",
    "LastName": "Last Name",
    "Phone": "Phone",
    "Company": "Company",
    "CreateAccount": "Create Account",
    "ConnectionError": "Sorry, we couldn't connect to the server",

    // Grid
    "Grid.NoItems": "No items",
    "Grid.RowsPerPage": "Rows per page",
    "Grid.PaginationIndex": "{{startIndex}}-{{endIndex}} of {{total}}",
    "Grid.ToggleColumns": "Columns",
    "Grid.ShowAllColumns": "Show All",
    "Grid.HideAllColumns": "Hide All",
    "Grid.FilterNoMatch": "No matching columns",
    "Grid.ResetFilters": "Reset filters",
    "Grid.Refresh": "Refresh",
    "Grid.ColumnsCount": "{{selected}}/{{total}}",

    // Date Range Picker
    "DateRange.From": "From",
    "DateRange.To": "To",
    "DateRange.Clear": "Clear",
    "DateRange.Apply": "Apply",

    // Export
    "Export": "Export",
    "Export.All": "All",
    "Export.Search": "Search results",

    // Download
    "Download": "Download",
    "Download.Progress": "Please wait while the file is being generated.",
    "Download.Error": "Something went wrong. Please try again.",
    "Download.Prompt": "Your download will begin automatically. If it didn't start, click download.",
    "Download.Retry": "Retry",

    // Report
    "Report.Generate": "Generate Report",
    "Report.NoItems": "No reports have been set up in the server.",
    "Reports": "Reports",

    // Nav Links
    "Link.Dashboard": "Dashboard",
    "Link.Maintenance": "Maintenance",
    "Link.Assets": "Assets",
    "Link.AssetModels": "Asset Models",
    "Link.AssetTypes": "Asset Types",
    "Link.UserDefinedAttributes": "User Defined Attributes",
    "Link.TagPatterns": "Tag Patterns",
    "Link.Scanners": "Scanners",
    "Link.CustomerGroups": "Customer Groups",
    "Link.Users": "Users",
    "Link.Reports": "Reports",
    "Link.Macros": "Macros",

    // Login
    "Login.UsernameRequired": "Username is required",
    "Login.PasswordRequired": "Password is required",
    "Login.UsernameCharLimit": "Must be 100 characters or less",
    "Login.UserPasswordError": "Invalid Username or Password",
    "Login.DontHaveAnAccount": "Don't have an account?",
    "Login.CantAccessAccount": "Can't access account?",
    "Login.External": "Login with {{name}}",

    // CreateNewAccount
    "CreateNewAccount.FirstNameRequired": "First Name is required",
    "CreateNewAccount.LastNameRequired": "Last Name is required",
    "CreateNewAccount.CompanyRequired": "Company is required",
    "CreateNewAccount.CharLimit": "Must be 100 characters or less",
    "CreateNewAccount.EmailRequired": "Email is required",
    "CreateNewAccount.InvalidEmailFormat": "Invalid Email format",
    "CreateNewAccount.PhoneNumberRequired": "Phone Number is required",
    "CreateNewAccount.InvalidPhoneNumber": "Invalid Phone Number",
    "CreateNewAccount.CompanyAlreadyExists": "Company already exists",
    "CreateNewAccount.EmailAlreadyExists": "Email already exists",
    "CreateAccount.HaveAnAccount": "Already have an account?",

    //Reset Password
    "ResetPassword.UserNameRequired": "Please specify your username",
    "ResetPassword": "Reset Password",
    "ResetPassword.LookForEmail": "Look for an email in your inbox to reset your password",
    "ResetPassword.MailSent": "Reset Password - Email Sent",

    //New Password
    "NewPassword.PasswordRequired": "Password is required",
    "NewPassword.PasswordCharLimit": "Must be at least 8 characters",
    "NewPassword.ConfirmPasswordRequired": "Confirm Password is required",
    "NewPassword.PasswordsMustMatch": "Passwords must match",
    "NewPassword.ChangePassword": "Change Password",
    "NewPassword.ConfirmPassword": "Confirm Password",
    "NewPassword.NewPassword": "New Password",
    "NewPassword.AtLeastOneLowerCase": "Password must contain at least one lower case letter",
    "NewPassword.AtLeastOneUpperCase": "Password must contain at least one upper case letter",
    "NewPassword.AtLeastOneDigit": "Password must contain at least one numeric",
    "NewPassword.OneSpecialCharacter": "Password must have contain a special character",
    "NewPassword.LinkExpired": "Your Asset Vue Password Reset link has expired",

    // Details Pane
    "Details": "Details",
    "Details.Assets": "Assets",
    "Details.InventoryState": "Inventory State",
    "Details.Show": "Show details pane",
    "Details.Hide": "Hide details pane",

    // Folder
    "Folder": "Folder",
    "Folder.Empty": "This folder is empty",
    "Folder.Name": "Name",
    "Folder.Name.CharLimit": "Folder name can be at most 1000 characters. Current length is {{length}}",
    "Folder.Name.ContainsPathSeparator": "Folder name should not contain '/'",
    "Folder.Path": "Path",
    "Folder.AddNew": "Add New Folder",
    "Folder.Edit": "Edit Folder",
    "Folder.FolderNameAlreadyExists": "Folder {{name}} already exists in the destination",
    "Folder.CreateNewFolder": "Create New Folder",
    "Folder.NotEmpty": "Children exists for {{id}} ",
    "Folder.DeleteFolderConfirmation": "Are you sure you want to delete the folder '{{name}}'?",
    "Folder.CannotDeleteMessage": "Folder '{{name}}' cannot be deleted because it is not empty. ",
    "Folder.DeletePopupTitle": "Delete Folder",
    "Folder.CannotDeletePopupTitle": "Folder is non-empty",
    "Folder.CreatedBy": "Created By",
    "Folder.CreatedOn": "Created On",
    "Folder.LastModified": "Last Modified",
    "Folder.LastModifiedBy": "Last Modified By",

    // Asset
    "Asset": "Asset",
    "Asset.Name": "Asset Name",
    "Asset.Model": "Model",
    "Asset.ModelName": "Model Name",
    "Asset.Placement": "Placement",
    "Asset.ParentAsset": "Parent Asset",
    "Asset.ParentAssetId": "Parent Asset",
    "Asset.ParentFolder": "Parent Folder",
    "Asset.ParentFolderId": "Parent Folder",
    "Asset.SerialNumber": "Serial Number",
    "Asset.Customer": "Customer",
    "Asset.Description": "Description",
    "Asset.RequiresTagNumber": "Requires Tag Number",
    "Asset.TagNumber": "Tag Number",
    "Asset.AlternateTagNumber": "Alternate Tag Number",
    "Asset.VerificationStatus": "Verification Status",
    "Asset.VerificationStatusId": "Verification Status",
    "Asset.Status": "Inventory State",
    "Asset.AssetStatusId": "Inventory State",
    "Asset.AssetClass": "Asset Class",
    "Asset.AssetType": "Asset Type Name",
    "Asset.FolderPath": "Folder Path",
    "Asset.Id": "Id",
    "Asset.ThirdPartyId": "Third Party Id",
    "Asset.Manufacturer": "Manufacturer",
    "Asset.LastModified": "Last Modified",
    "Asset.Created": "Date Added",
    "Asset.LastScanned": "Last Scanned",
    "Asset.Model.Height": "Height",
    "Asset.Model.Width": "Width",
    "Asset.Model.Depth": "Depth",
    "Asset.Model.NumberOfSlots": "Number of Slots",
    "Asset.Model.Description": "Model Description",

    "Asset.AddNew": "Add New Asset",
    "Asset.Edit": "Edit Asset",
    "Asset.BasicInfo": "Basic Info",
    "Asset.UserDefinedFields": "User Defined Fields",
    "Asset.UserDefinedFields.Empty": "No user defined fields",
    "Asset.Tabs": "Asset Tabs",
    "Asset.InfoTab": "Asset Info",
    "Asset.ScanHistoryTab": "Scan History",
    "Asset.NotesTab": "Notes",
    "Asset.AttachmentsTab": "Attachments",
    "Asset.AssetHistory": "Asset History",
    "Asset.Empty": "No assets",
    "Asset.ContainedAsset": "Contained Asset",
    "Asset.SubContainedAsset": "Sub-contained Asset",
    "Asset.DeleteFailedMessage": "Failed, due to: asset contains child assets. Please move or delete the child assets and try again.",
    "Asset.License.Exceeded": "Allowed asset count for this account has been exceeded.",
    "Assets.Manage": "Manage Assets",
    "Assets.AutoRefresh": "Auto refresh",

    // Select Asset
    "SelectAsset.Search": "name/serial number/tag number",
    "SelectAsset.Folder": "Search Folder",

    // Consumable
    "Consumable": "Consumable",

    // Mass Update Panel
    "MassUpdate.Panel": "Mass Update Panel",
    "MassUpdatePanel.EmptyValueClearsProperty": "Leave empty to clear existing value",
    "MassUpdatePanel.Empty": "No update actions available",

    // User Defined Field
    "UserDefinedField": "User Defined Attribute",
    "UserDefinedField.Name": "Key",
    "UserDefinedField.Type": "Field Type",
    "UserDefinedField.AllowedValues": "Allowed Values",
    "UserDefinedField.Required": "Required",
    "UserDefinedField.Unique": "Unique",
    "UserDefinedField.Ordinal": "Order",
    "UserDefinedField.AppliesTo": "Applies To",
    "UserDefinedField.AssetTypes": "Asset Types",
    "UserDefinedField.Expression": "Expression",
    "UserDefinedField.Maintenance": "Manage User Defined Attributes",
    "UserDefinedField.Edit": "Edit User Defined Attribute",
    "UserDefinedField.Add": "Add User Defined Attribute",
    "UserDefinedField.AllowedValues.HelperText": "Separate allowed values with commas",
    "UserDefinedField.AllowedValues.LengthError": "Each value should be fewer than 100 characters",
    "UserDefinedField.Name.Exists": "User defined key, {{name}}, already exists",
    "UserDefinedField.Name.Reserved": "{{name}} is a reserved name",
    "UserDefinedField.Expression.ParseError": "Failed to parse the expression",
    "UserDefinedField.Unique.DuplicatesError": "This field cannot be updated to unique as it already has duplicate values",
    "UserDefinedField.ReferencedInACalculatedField": "This field is being referenced in one or more calculated fields. Please update or delete them before continuing.",

    // Field Types
    "DATE": "Date",
    "NUMBER": "Number",
    "PICKLIST": "Picklist",
    "TEXT": "Text",
    "CALCULATED": "Calculated",

    // Applies to
    "ALL": "All",
    "SERIALIZED_ASSETS": "Serialized Assets",
    "CONSUMABLES": 'Consumables',
    "SPECIFIC_ASSET_TYPES": 'Specific Asset Types',

    // Delete
    "DeletePopupTitle": "Delete $t({{type}})",
    "DeleteConfirmation": "Do you want to delete the $t({{type}}), '{{name}}'?",
    "DeleteFailedPopupTitle": "Delete Failed",

    // Validations
    "RequiredField": "This field is required",
    "NamedRequiredField": "$t({{name}}) is required",
    "CharLimit": "Must be {{length}} characters or less",
    "UnableToValidate": "Could not validate. Please try again",
    'EditCancelPrompt': 'You might lose your changes. Continue anyway?',
    "InvalidDate": "Please enter a valid date",
    "InvalidNumber": "Please enter a valid number",
    "NumberOutOfBounds": "Number should be between {{min}} and {{max}}",
    "AlreadyInUse": "This $t({{name}}) is already in use",
    "UniqueField": "This value is already in use",

    // Notifications
    "Notify.Saved": "$t({{name}}) was successfully saved!",
    "Notify.Deleted": "$t({{name}}) was successfully deleted!",
    "Notify.DeleteFailed": "Failed to delete the $t({{name}})!",
    "Notify.CorrectErrors": "Please correct the errors before continuing!",

    // Units
    "Asset.CountWithUnit": "{{count}} asset",
    "Asset.CountWithUnit_plural": "{{count}} assets",
    "ContainedAsset.CountWithUnit": "{{count}} contained asset",
    "ContainedAsset.CountWithUnit_plural": "{{count}} contained assets",
    "Sub-containedAsset.CountWithUnit": "{{count}} sub-contained asset",
    "Sub-containedAsset.CountWithUnit_plural": "{{count}} sub-contained assets",
    "Folder.CountWithUnit": "{{count}} folder",
    "Folder.CountWithUnit_plural": "{{count}} folders",
    "Error.CountWithUnit": "{{count}} error",
    "Error.CountWithUnit_plural": "{{count}} errors",
    "Warning.CountWithUnit": "{{count}} warning",
    "Warning.CountWithUnit_plural": "{{count}} warnings",
    "Verified.CountWithUnit": "{{count}} verified",
    "Verified.CountWithUnit_plural": "{{count}} verified",
    "Missing.CountWithUnit": "{{count}} missing tag or asset",
    "Missing.CountWithUnit_plural": "{{count}} missing tags or assets",
    "SelectedItems": "{{count}} selected",
    "Scan.TagsFound": "{{count}} tag scanned",
    "Scan.TagsFound_plural": "{{count}} tags scanned",
    "Scan.AssetsFound": "{{count}} asset scanned",
    "Scan.AssetsFound_plural": "{{count}} assets scanned",
    "ContainerScan.Found": "{{count}} found",
    "ContainerScan.New": "{{count}} new",
    "ContainerScan.Moved": "{{count}} moved",
    "ContainerScan.Missing": "{{count}} missing",
    "ContainerScan.Expected": "{{count}} expected",
    "Assets.Selected": "{{count}} asset selected",
    "Assets.Selected_plural": "{{count}} assets selected",
    "Attachments.FilesSelected": "{{count}} file selected",
    "Attachments.FilesSelected_plural": "{{count}} files selected",
    "Upload.ChooseFiles": "Drag and drop or Choose your file",
    "Upload.ChooseFiles_plural": "Drag and drop or Choose your files",

    // Verification Status
    "Verified": "Verified",
    "Tag Missing": "Tag Missing",
    "Not Verified": "Not Verified",
    "Missing In Scan": "Missing In Scan",
    "Moved": "Moved",
    "Portal Scan": "Portal Scan",
    "Confirmed Missing": "Confirmed Missing",

    // Asset Class
    "BASE": "Base",
    "CONTAINED": "Contained",
    "SUB_CONTAINED": "Sub-contained",
    "NON_MOUNTED": "Non-mounted",
    "AssetClass.All": "All classes",

    // Asset Type
    "AssetType": "Asset Type",
    "AssetType.Name": "Name",
    "AssetType.Name.CharLimit": "Asset type name can be at most 1000 characters. Current length is {{length}}",
    "AssetType.Add": "Add New Asset Type",
    "AssetType.Edit": "Edit Asset Type",
    "AssetType.AssetTypeNameAlreadyExists": "Asset Type name {{name}} already exists",
    "AssetType.DeleteConfirmation": "Do you want to delete the Asset Type '{{name}}'?",
    "AssetType.CannotDeleteMessage": "The Asset Type '{{name}}' cannot be deleted. It is being used by Asset Models or User Defined Fields. ",
    "AssetType.DeletePopupTitle": "Delete Asset Type",
    "AssetType.CannotDeletePopupTitle": "Cannot delete Asset Type",
    "AssetType.Empty": "No asset types",
    "AssetType.Maintenance": "Manage Asset Types",

    // Model
    "Model.Name": "Model Name",
    "Model.Manufacturer": "Manufacturer",
    "Model.Description": "Description",
    "Model.ProductLine": "Product Line",
    "Model.AssetType": "Asset Type Name",
    "Model.AssetClass": "Asset Class",
    "Model.Height": "Height",
    "Model.Width": "Width",
    "Model.Depth": "Depth",
    "Model.HeightInRackUnits": "Height in Rack Units",
    "Model.InRackUnits": "In Rack Units",
    "Model.NumberOfSlots": "Number of Slots",
    "Model.Maintenance": "Manage Asset Models",
    "Model.Empty": "No asset models",
    "Model.Edit": "Edit Asset Model",
    "Model.AddNewAssetModel": "Add New Asset Model",
    "Model.Name.Search": "Search model name",
    "Model.Description.Search": "Search description",
    "Model.ProductLine.Search": "Search product line",
    "Model.ModelNameAlreadyExists": "The Asset Model {{name}} already exists",
    "Model.DeleteFolderConfirmation": "Do you want to delete the Asset Model '{{name}}'?",
    "Model.CannotDeleteMessage": "The Asset Model '{{name}}' cannot be deleted. It is being used by Assets. ",
    "Model.DeletePopupTitle": "Delete Asset Model",
    "Model.CannotDeletePopupTitle": "Cannot delete Asset Model",

    // Manufacturer
    "Manufacturer.Name": "Manufacturer Name",
    "Manufacturer.AddNew": "Add New Manufacturer",
    "Manufacturer.AddPrompt": "Add \"{{name}}\"",

    // Scanner
    "Scanner.Maintenance": "Manage Scanners",
    "Scanner.Name": "Name",
    "Scanner.Type": "Type",
    "Scanner.FolderAndContainer": "Folder/Container",
    "Scanner.LastCommunication": "Last Communication",
    "Scanner.AllowMacros": "Allow Macros",
    "Scanner.AllowRealtimeMove": "Allow Realtime Move",
    "Scanner.AllowAutoCreate": "Allow Auto Create",
    "Scanner.Add": "Add Scanner",
    "Scanner.Edit": "Edit Scanner",
    "Scanner.Folder": "Folder",
    "Scanner.Asset": "Container",
    "Scanner.TotalScans": "Scan Count",
    "Scanner.MacrosAllowed": "Macros allowed",
    "Scanner.DeletePopupTitle": "Delete Scanner",
    "Scanner.DeleteConfirmation": "Do you want to delete the scanner, '{{name}}'?",

    // Macro
    "Macro.Maintenance": "Manage Macros",
    "Macro.Name": "Name",
    "Macro.Type": "Type",
    "Macro.Property": "Property",
    "Macro.Comparator": "Comparator",
    "Macro.CompareValue": "Compare Value",
    "Macro.CompareOriginal": "Compare to original value",
    "Macro.Add": "Add",
    "Macro.Edit": "Edit",
    "Macro.DeletePopupTitle": "Delete Macro",
    "Macro.DeleteConfirmation": "Do you want to delete the macro, '{{name}}'?",
    "Macro.Scanner.Name": "Scanner Name",
    "Macro.Tasks": "Tasks",
    "Macro.Task.Ordinal": "Order",
    "Macro.Task.Property": "Property",
    "Macro.Task.Type": "Type",
    "Macro.Task.Value": "Value",
    "Macro.Task.EmptyValueClearsProperty": "Clear if empty",
    "Macro.Task.Add": "Add Task",
    "Macro.Task.Delete": "Remove macro task",

    "Macro.Type.All": "All",
    "Macro.Type.ON_INSERT": "On Insert",
    "Macro.Type.ON_UPDATE": "On Update",
    "Macro.Type.ON_DELETE": "On Delete",
    "Macro.Type.ON_SCAN": "On Scan",
    "Macro.Type.MANUAL": "Manual",
    "Macro.Task.Type.SET_PROPERTY": "Set Property",
    "Macro.Task.Type.SEND_EMAIL": "Send Email",
    "Macro.Comparator.All": "All",
    "Macro.Comparator.NONE": "None",
    "Macro.Comparator.EQUALS": "Equals",
    "Macro.Comparator.NOT_EQUALS": "Not equals",
    "Macro.Comparator.GREATER_THAN": "Greater than",
    "Macro.Comparator.LESS_THAN": "Less than",
    "Macro.AssetProperties": "Asset Properties",
    "Macro.UserDefinedFields": "User Defined Fields",

    // User
    "User.Name": "Name",
    "User.FirstName": "First Name",
    "User.LastName": "Last Name",
    "User.Username": "Username",
    "User.Email": "Email",
    "User.Role": "Role",
    "User.AccountName": "Account",
    "User.UseEmailAsUsername": "Use my email as Username",
    "User.AddNewUser": "Add New User",
    "User.UserNameAlreadyExists": "Username '{{username}}' already exists",
    "User.ReadOnly": "Read Only Access",
    "User.Invite": "Invite",
    "User.Scanner": "Scanner",
    "User.Status": "Invite Status",
    "User.Edit": "Edit User",
    "User.ResendInvite": "Resend Invite",
    "User.CancelInvite": "Cancel Invite",
    "User.Delete": "Delete",
    "User.DeleteUserConfirmation": "Do you want to delete the User '{{name}}'?",
    "User.DeletePopupTitle": "Delete User",
    "User.EmailAlreadyExists": "Email '{{email}}' already exists",
    "User.ResendInviteConfirmation": "Do you want to resend the invitation to the User '{{name}}'?",
    "User.ResendInviteTitle": "Resend Invite",
    "User.Management": "User Management",

    // Import
    "Import": "Import",
    "Import.Logs": "Import Logs",
    "Import.GoToExisting": "Go to existing import",
    "Import.By": "Import processed by {{name}}",
    "Import.Commit": "Commit",
    "Import.StartedAt": "Started on",
    "Import.FinishedAt": "Completed on",
    "Import.Status.IN_PROGRESS": "In progress",
    "Import.Status.IN_PROGRESS.Message": "Import in progress. Rows are being validated. Please wait until the assets are staged.",
    "Import.Status.FAILED": "Failed",
    "Import.Status.FAILED.Message": "The import was failed. Check logs to see errors.",
    "Import.Status.STAGED": "Staged",
    "Import.Status.STAGED.Message": "Import is validated and staging is complete. Commit import to save assets or cancel the import to start a new one.",
    "Import.Status.SUCCEEDED": "Success",
    "Import.Status.SUCCEEDED.Message": "The import was successfully completed.",
    "Import.Status.CANCELLED": "Cancelled",
    "Import.Status.CANCELLED.Message": "The import was cancelled by user.",
    "Import.Status.COMMITTING": "Commit in progress",
    "Import.Status.COMMITTING.Message": "The import is confirmed. Please wait while the assets are being saved.",
    "Import.FileName": "File Name ",
    "Import.Assets": "Import Assets",
    "Import.Select": "Select",
    "Import.Upload": "Upload",
    "Import.SelectFile": "Please select a CSV file",
    "Import.FileSizeLimit": "File size should be less than {{size}}",
    "Import.Success": "Import has completed successfully",
    "Import.Failed": "Import has failed",
    "Import.Results": "Import Results",
    "Import.CreateNewFolders": "Create missing folders in folder paths",
    "Import.Log.ShowInfos": "Show infos",
    "Import.Log.ShowWarnings": "Show warnings",
    "Import.Log.ShowErrors": "Show errors",
    "Import.Log.Empty": "No items",
    "Import.Log.LoadNew": "Load new logs",
    "Import.Log.Sort": "Sort by newest/oldest",
    "Import.Log.Export": "Export logs to CSV",

    // Scanner Type
    "Scanner.Type.All": "All",
    "PORTAL": "Portal",
    "WEDGE": "Wedge",
    "REALTIME_CONTAINER": "Realtime Container",
    "REALTIME_FOLDER": "Realtime Folder",

    // Roles
    "Admin": 'Admin',
    "General User": 'General User',
    "Customer": 'Customer',
    "Super User": 'Super User',
    "System Admin": 'System Admin',

    //YesNo
    "YES": 'Yes',
    "NO": 'No',

    //Tag Patterns
    "TagPattern.Pattern": "Pattern",
    "TagPattern.Name.CharLimit": "Recognized Tag Pattern can be at most 20 characters. Current length is {{length}}",
    "TagPattern.Add": "Add New Recognized Tag Pattern",
    "TagPattern.Edit": "Edit Recognized Tag Pattern",
    "TagPattern.TagPatternAlreadyExists": "The Tag Pattern {{name}} already exists",
    "TagPattern.DeleteConfirmation": "Do you want to delete the Tag Pattern '{{name}}'?",
    "TagPattern.DeletePopupTitle": "Delete Recognized Tag Pattern",
    "TagPattern.Empty": "No Recognized Tag Patterns",
    "TagPattern.Maintenance": "Manage Recognized Tag Number Patterns",

    // Scan
    "Scan.QuickScan": "Quick Scan",
    "Scan.ContainerScan": "Container Scan",
    "Scan.SelectContainer": "Select Container",
    "Scan.Stop": "Stop",
    "Scan.StopPrompt": "Stop scanning to view or edit asset details",
    "Scan.Failed": "Failed to save scan",
    "Scan.Retry": "Retry",
    "Scan.NotFound": "Tag not assigned",
    "Scan.InvalidPattern": "Tag pattern not recognized",
    "Scan.HideUnknownTags": "Hide unknown tags",
    "Scan.QuickScanResults": "Quick Scan Results",
    "Scan.ContainerScanResults": "Container Scan Results",
    "Scan.NoScanSession": "No Active scan session",
    "Scan.NewTagsScanned": "New tags scanned",
    "Scan.TagNumber": "Tag Number",
    "Scan.ScannedTagNumber": "Scanned Tag Number",
    "Scan.ScanDateUser": "Scan Date / Scanned By",
    "Scan.AssetNameSerialNumber": "Container Asset / Serial Number",
    "Scan.ManufacturerModel": "Manufacturer / Model ",
    "Scan.Folder": "Folder",
    "Scan.Antenna": "Antenna",
    "Scan.Scanned": "Scanned on",

    //User Profile
    "UserProfile": "User Profile",
    "UserProfile.Edit": "Edit User Profile",
    "UserProfile.TimeZone": "Time Zone",
    "UserProfile.ViewProfile": "View Profile",

    // View
    "View": "View",
    "View.SwitchView": "Switch View",
    "View.Save": "Save",
    "View.SaveAs": "Save As",
    "View.SavePrompt": "Save current view",
    "View.SaveAsPrompt": "Save current view as a new view",
    "View.Name": "Name",
    "View.Settings": "View Settings",
    "View.Add": "Add View",
    "View.Edit": "Edit View",
    "View.Select": "select view",
    "View.Delete": "Delete",
    "View.DeletePrompt": "Delete current view",

    //Change Password
    "ChangePassword.CurrentPassword": "Current Password",
    "ChangePassword.CurrentPasswordRequired": "Current Password is required",
    "ChangePassword.PasswordRequired": "New Password is required",
    "ChangePassword.IncorrectPassword": "Current password is incorrect",
    "ChangePassword.PasswordsDoNotMatch": "Passwords do not match",
    "ChangePassword.PasswordChanged": "Your password has been updated",
    "ChangePassword.ConfirmPasswordRequired": "Confirm Password is required",
    "ChangePassword.ConfirmPassword": "Confirm Password",
    "ChangePassword.NewPassword": "New Password",
    "ChangePassword.Length": "Password must be at least 8 characters",
    "ChangePassword.AtLeastOneLowerCase": "Password must contain at least one lower case letter",
    "ChangePassword.AtLeastOneUpperCase": "Password must contain at least one upper case letter",
    "ChangePassword.AtLeastOneDigit": "Password must contain at least one numeric",
    "ChangePassword.OneSpecialCharacter": "Password must contain at least one special character",
    "ChangePassword.LinkExpired": "Your Asset Vue Password Reset link has expired",
    "ChangePassword.ChangePassword": "Change Password",
    "ChangePassword.ChangeMyPassword": "Change My Password",
    "ChangePassword.RequirePasswordChange": "Require Password Change",
    "ChangePassword.Changed": "Password Changed",

    // Asset History
    "AssetHistory.Empty": "none",
    "AssetHistory.UnknownUser": "Unknown User",
    "AssetHistory.UserEntry.CREATE": "<strong>{{name}}</strong> created the asset",
    "AssetHistory.UserEntry.UPDATE": "<strong>{{name}}</strong> made the following changes",
    "AssetHistory.UserEntry.DELETE": "<strong>{{name}}</strong> deleted the asset",
    "AssetHistory.UserEntry.RESTORE": "<strong>{{name}}</strong> restored the asset",

    // Theme
    "Theme": "Theme",
    "Theme.light": "Light",
    "Theme.dark": "Dark",

    // License
    "License": "License",
    "License.Usage": "Used {{count}} of {{total}} assets allowed ({{percentage}}%)",
    "License.Exceeded": "License count for this account has been exceeded.",
    "License.Warning": "Addition of new assets will be restricted after 105% of allowed assets.",
    "License.Error": "Addition of new assets is restricted as the asset count is more than the allowed asset count.",
    "License.ContactEmail": "Please contact <emailLink href='mailto:{{email}}'>{{email}}</emailLink>",

    "Database.PendingChanges": "Database has pending changes.",

    // Attachments
    "Attachment": "Attachment",
    "Attachment.Filename": "Filename",
    "Attachment.MimeType": "Mime Type",
    "Attachment.CreatedBy": "Created By",
    "Attachment.Created": "Created On",
    "Attachment.Add": "Add Attachments",
    "Attachment.Remove": "Remove file",
    "Attachment.SelectFilePrompt": "Please select at least one file to upload",
    "Attachment.HasErrors": "Attached file has errors",
    "Attachment.RemovedFilesWithErrors": "Please remove the files with errors before continuing",
    "Attachment.ExceedsSizeError": "Attached file size should be less than {{size}}",
    "Attachment.InvalidFileType": "Invalid file type",
    "Attachment.ValidExtensions": "Supported file extensions are, {{extensions}}",
    "Attachment.InvalidExtensions": "The following file extensions are not allowed; {{extensions}}",
    "Attachment.UploadSuccess": "Successfully uploaded attachments!",
    "Attachment.NoPreview": "No Preview available",

    // Directories
    "Directory.Name": "Name",
    "Directory.Details": "Details",
    "Directory.LastModified": "Last Modified",
    "Directory.DisplayAsIcons": "Display as icons",
    "Directory.DisplayAsList": "Display as list",
}

export default english;