import { TextField } from '@material-ui/core';
import { useField } from 'formik';

// Context Bound Text Field
export default function FormikBoundTextField(props) {

    const [field, meta] = useField(props);

    return (
        <TextField
            error={meta.touched && !!meta.error}
            helperText={meta.touched && meta.error}
            {...field}
            {...props}
        />
    )
}