import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Layout from '../auth/Layout';
import ChangePassword from './ChangePassword';

export default function RequirePasswordChange() {

    const { t } = useTranslation();
    const history = useHistory();

    return (
        <Layout title={t('ChangePassword.RequirePasswordChange')}>
            <ChangePassword onCancel={() => history.push('./Logout')} />
        </Layout>
    )
}