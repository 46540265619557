import { React } from 'react';
import { FormHelperText, Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { Formik } from 'formik';

import { useAuth } from './use-auth';
import Layout from './Layout';
import TextField from './TextField';
import AuthForm from './AuthFormContainer';
import SubmitButtonsContainer from '../common/SubmitButtonsContainer';
import PasswordValidations from './PasswordValidations';

export default function NewPassword() {

    const { t } = useTranslation();

    const history = useHistory();

    const { search } = useLocation();
    const query = new URLSearchParams(search);
    const userName = query.get('userName');
    const token = query.get('token');

    const { confirmPasswordReset } = useAuth();

    const validate = values => {
        const errors = {};

        if (!values.password) {
            errors.password = t('NewPassword.PasswordRequired');
        }
        else {
            if (values.password.length < 8) {
                errors.length = true;
            }

            if (!/(?=.*[a-z])/.test(values.password)) {
                errors.lowerCase = true;
            }

            if (!/(?=.*[A-Z])/.test(values.password)) {
                errors.upperCase = true;
            }

            if (!/(?=.*[0-9])/.test(values.password)) {
                errors.numeric = true;
            }

            if (!/(?=.[!@#$%^&])/.test(values.password)) {
                errors.specialChar = true;
            }
        }

        if (!values.confirmPassword) {
            errors.confirmPassword = t('NewPassword.ConfirmPasswordRequired');
        }
        else if (values.confirmPassword !== values.password) {
            errors.confirmPassword = t('NewPassword.PasswordsMustMatch');
        }

        return errors;
    };

    const onSubmit = async (values, { setSubmitting, setErrors }) => {
        try {
            let isPasswordChanged = await confirmPasswordReset(userName, values.password, token);
            if (isPasswordChanged) {
                return history.push('./Login');
            }
            setErrors({ submitError: t('NewPassword.LinkExpired') })
        }
        catch {
            setErrors({ submitError: t('UnknownError') })
        }
        setSubmitting(false);
    }

    const gotoLoginPage = async () => {
        return history.push('./Login');
    }

    return (
        <Layout title={t('NewPassword.ChangePassword')}>
            <Formik
                validate={validate}
                onSubmit={onSubmit}
                validateOnBlur={false}
                initialValues={initialValues}
            >
                {({ values, errors }) => (
                    <AuthForm>
                        <TextField name='password' label={t('NewPassword.NewPassword')} type='password' autoFocus />
                        <TextField name='confirmPassword' label={t('NewPassword.ConfirmPassword')} type='password' />

                        <PasswordValidations errors={errors} hasValue={!!values.password} />

                        <SubmitButtonsContainer>
                            <Button variant='contained' onClick={gotoLoginPage}>
                                {t('Cancel')}
                            </Button>
                            <Button type='submit' variant='contained' color='primary'>
                                {t('Ok')}
                            </Button>
                            {!!errors.submitError &&
                                <FormHelperText error>{errors.submitError}</FormHelperText>
                            }
                        </SubmitButtonsContainer>
                    </AuthForm>
                )}
            </Formik>
        </Layout>
    );
}

const initialValues = {
    password: '',
    confirmPassword: ''
};