import { ListItem, ListItemText } from '@material-ui/core';

const AssetItem = ({ name, tagNumber }) => (
    <ListItem divider>
        <ListItemText
            primary={name}
            secondary={tagNumber}
        />
    </ListItem>
)

export default AssetItem;