import React from 'react';
import {
    Avatar,
    Typography,
    IconButton,
    makeStyles,
    Box,
    Drawer,
    Divider,
    ButtonGroup,
    Button,
    alpha
} from '@material-ui/core';
import {
    Brightness4,
    BrightnessHigh,
    CloseRounded,
    ExitToAppRounded,
    WarningRounded
} from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';

import { useAuth } from '../auth/use-auth';
import CenterBox from '../common/CenterBox';
import DotSeparator from '../common/DotSeparator';
import { THEME_MODE, UI_VERSION } from '../../constants/common';
import useTheme from '../../theme';
import EditUserPassword from '../user-profile/EditUserPassword';
import useOpenClose from '../common/use-open-close';
import EditUserProfile from '../user-profile/EditUserProfile';
import Title from './Title';
import License from './License';
import { useConfig } from '../bootstrap/ConfigProvider';

const useStyles = makeStyles(theme => ({
    avatar: {
        color: theme.palette.common.white,
        background: theme.palette.info.dark,
        width: 64,
        height: 64,
        fontSize: 24,
        fontWeight: 500,
        marginBottom: 8
    },
    capitalize: { textTransform: 'capitalize' },
    selected: {
        color: theme.palette.primary.main,
        background: alpha(theme.palette.primary.main, 0.1)
    },
    license: {
        background: alpha(theme.palette.error.main, 0.1)
    }
}));

export default function ProfileDrawer({ open, handleClose }) {
    const classes = useStyles();
    const { t } = useTranslation();
    const { user } = useAuth();
    const history = useHistory();

    const { apiVersion, hasPendingDatabaseChanges } = useConfig();
    const { type, selectTheme } = useTheme();
    const profileToggle = useOpenClose();
    const editPasswordToggle = useOpenClose();

    const profileChar = (user.firstName || user.username)[0].toUpperCase();

    return (
        <Drawer anchor='right' open={open} onClose={handleClose}>
            <Box
                width={310}
                bgcolor='background.paper'
                flex={1}
                display='flex'
                flexDirection='column'
            >
                <Box
                    display='flex'
                    alignItems='center'
                    justifyContent='space-between'
                    p={0.5}
                    pl={2}
                >
                    <Typography variant='button' color='textSecondary'>
                        {user.account.name}
                    </Typography>
                    <IconButton onClick={handleClose}>
                        <CloseRounded fontSize='inherit' />
                    </IconButton>
                </Box>
                <Divider light />

                {/* <Box px={2} pt={2}>
                    <Title>{t('Account')}</Title>
                </Box> */}
                <CenterBox flexDirection='column' p={2}>
                    <Avatar className={classes.avatar}>{profileChar}</Avatar>
                    <Typography variant='body1'>
                        <strong>
                            {user.firstName} {user.lastName}
                        </strong>
                    </Typography>
                    <Typography variant='body1' gutterBottom>
                        {user.email || user.username}
                    </Typography>
                    <Button className={classes.capitalize} onClick={profileToggle.handleClickOpen}>
                        {t('UserProfile.ViewProfile')}
                    </Button>
                    <Button
                        style={{ textTransform: 'capitalize' }}
                        onClick={editPasswordToggle.handleClickOpen}
                    >
                        {t('ChangePassword.ChangePassword')}
                    </Button>
                </CenterBox>
                <Divider light />

                <CenterBox p={2}>
                    <Button
                        startIcon={<ExitToAppRounded />}
                        variant='outlined'
                        className={classes.capitalize}
                        onClick={() => history.push('/logout')}
                    >
                        {t('SignOut')}
                    </Button>
                </CenterBox>
                <Divider light />

                <License />
                <Divider light />

                <Box p={2}>
                    <Title>{t('Theme')}</Title>
                    <ButtonGroup aria-label='theme-mode' fullWidth>
                        <Button
                            startIcon={<BrightnessHigh />}
                            className={clsx(
                                classes.capitalize,
                                type === THEME_MODE.LIGHT && classes.selected
                            )}
                            onClick={() => selectTheme(THEME_MODE.LIGHT)}
                        >
                            {t(`Theme.${THEME_MODE.LIGHT}`)}
                        </Button>
                        <Button
                            startIcon={<Brightness4 />}
                            className={clsx(
                                classes.capitalize,
                                type === THEME_MODE.DARK && classes.selected
                            )}
                            onClick={() => selectTheme(THEME_MODE.DARK)}
                        >
                            {t(`Theme.${THEME_MODE.DARK}`)}
                        </Button>
                    </ButtonGroup>
                </Box>
                <Divider light />

                <Box flex={1} />
                <Divider light />

                <CenterBox p={1} justifyContent='space-evenly'>
                    <Typography variant='caption' color='textSecondary'>
                        UI v{UI_VERSION}
                    </Typography>
                    <Typography color='textSecondary'>
                        <DotSeparator />
                    </Typography>
                    <Typography variant='caption' color='textSecondary'>
                        API v{apiVersion}
                    </Typography>
                </CenterBox>
                <Divider light />
                {hasPendingDatabaseChanges && (
                    <CenterBox p={1} justifyContent='center' gridGap={10}>
                        <WarningRounded color='error' />
                        <Typography variant='caption' component='p' color='error'>
                            {t('Database.PendingChanges')}
                        </Typography>
                    </CenterBox>
                )}
            </Box>

            <EditUserPassword
                open={editPasswordToggle.open}
                onClose={editPasswordToggle.handleClose}
            />

            <EditUserProfile
                open={profileToggle.open}
                onClose={profileToggle.handleClose}
                onSuccess={profileToggle.handleClose}
            />
        </Drawer>
    );
}
