import React from 'react';

import { ThemeProvider } from './theme';

import { ApolloProvider } from '@apollo/client';
import client from './graphql/apollo-client';

import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';

import { BrowserRouter } from 'react-router-dom';
import Router from './Router';

import MsalAuthProvider from './msal/MsalAuthProvider';
import { ConfigProvider } from './components/bootstrap/ConfigProvider';
import OidcAuthProvider from './oidc/OidcAuthProvider';
import AddUsetiful from './components/bootstrap/AddUsetiful';
import Notifications from './components/Notifications';

function App() {
    return (
        <I18nextProvider i18n={i18n}>
            <ThemeProvider>
                <ConfigProvider>
                    <ApolloProvider client={client}>
                        <MsalAuthProvider>
                            <OidcAuthProvider>
                                <BrowserRouter basename={process.env.PUBLIC_URL}>
                                    <Router />
                                </BrowserRouter>
                            </OidcAuthProvider>
                        </MsalAuthProvider>
                    </ApolloProvider>
                    <Notifications />
                    <AddUsetiful />
                </ConfigProvider>
            </ThemeProvider>
        </I18nextProvider>
    );
}

export default App;
