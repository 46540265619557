import React from 'react';
import { Box } from '@material-ui/core';

const CenterBox = props => <Box
    display='flex'
    justifyContent='center'
    alignItems='center'
    {...props}
/>

export default CenterBox;