import React, { useEffect } from 'react';
import { FormHelperText } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Formik } from 'formik';
import MaskedInput from 'react-text-mask';
import PropTypes from 'prop-types';

import { useAuth } from './use-auth';
import Layout from './Layout';
import TextField from './TextField';
import AuthForm from './AuthFormContainer';
import SwitchAuthLink from './SwitchAuthLink';
import SubmitButton from './SubmitButton';
import { useConfig } from '../bootstrap/ConfigProvider';

function TextMaskPhone(props) {
    const { inputRef, ...other } = props;

    return (
        <MaskedInput
            {...other}
            ref={ref => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={PHONE_MASK}
            placeholderChar={'\u2000'}
        />
    );
}
TextMaskPhone.propTypes = {
    inputRef: PropTypes.func.isRequired
};

const CreateNewAccount = () => {
    const { t } = useTranslation();

    const { createNewAccount } = useAuth();
    const history = useHistory();
    const { isMultiTenancyEnabled, requiresSingleTenantSignUp } = useConfig();

    useEffect(() => {
        if (!isMultiTenancyEnabled && !requiresSingleTenantSignUp) {
            history.replace('/login');
        }
    }, [history, isMultiTenancyEnabled, requiresSingleTenantSignUp]);

    const validate = values => {
        const errors = {};

        if (!values.firstName) {
            errors.firstName = t('CreateNewAccount.FirstNameRequired');
        } else if (values.firstName.length > 100) {
            errors.firstName = t('CreateNewAccount.CharLimit');
        }

        if (!values.lastName) {
            errors.lastName = t('CreateNewAccount.LastNameRequired');
        } else if (values.lastName.length > 100) {
            errors.lastName = t('CreateNewAccount.CharLimit');
        }

        if (!values.company) {
            errors.company = t('CreateNewAccount.CompanyRequired');
        } else if (values.company.length > 100) {
            errors.company = t('CreateNewAccount.CharLimit');
        }

        if (!values.email) {
            errors.email = t('CreateNewAccount.EmailRequired');
        } else if (!/\w+([-+._]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/.test(values.email)) {
            errors.email = t('CreateNewAccount.InvalidEmailFormat');
        }

        if (!values.phone) {
            errors.phone = t('CreateNewAccount.PhoneNumberRequired');
        }

        return errors;
    };

    const onSubmit = async (values, { setSubmitting, setErrors }) => {
        try {
            const response = await createNewAccount(
                values.company,
                values.firstName,
                values.lastName,
                values.email,
                values.phone
            );

            if (response === true) {
                return history.push('./ThankYou');
            } else if (response?.error === 'Company already exists') {
                setErrors({ company: t('CreateNewAccount.CompanyAlreadyExists') });
            } else if (response?.error === 'Email already exists') {
                setErrors({ email: t('CreateNewAccount.EmailAlreadyExists') });
            } else {
                setErrors({ submitError: t('UnknownError') });
            }
        } catch {
            setErrors({ submitError: t('UnknownError') });
        }
        setSubmitting(false);
    };

    return (
        <Layout title={t('CreateNewAccount')}>
            <Formik
                validate={validate}
                onSubmit={onSubmit}
                validateOnBlur={false}
                initialValues={initialValues}
            >
                {({ errors, isSubmitting }) => (
                    <AuthForm>
                        <TextField name='firstName' label={t('FirstName')} autoFocus />
                        <TextField name='lastName' label={t('LastName')} />
                        <TextField name='company' label={t('Company')} />
                        <TextField name='email' label={t('Email')} />
                        <TextField
                            name='phone'
                            label={t('Phone')}
                            InputProps={{
                                inputComponent: TextMaskPhone
                            }}
                        />
                        <div>
                            <SubmitButton isSubmitting={isSubmitting}>
                                {t('CreateAccount')}
                            </SubmitButton>
                            {!!errors.submitError && (
                                <FormHelperText error>{errors.submitError}</FormHelperText>
                            )}
                        </div>
                    </AuthForm>
                )}
            </Formik>
            <SwitchAuthLink
                message={t('CreateAccount.HaveAnAccount')}
                to='/login'
                linkLabel={t('Login')}
            />
        </Layout>
    );
};

export default CreateNewAccount;

const initialValues = {
    firstName: '',
    lastName: '',
    company: '',
    email: '',
    phone: ''
};

const PHONE_MASK = [
    '+',
    '1',
    ' ',
    '(',
    /[1-9]/,
    /\d/,
    /\d/,
    ')',
    ' ',
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
    /\d/,
    /\d/
];
