import React from 'react';
import { SnackbarProvider, useSnackbar } from 'notistack';
import { IconButton } from '@material-ui/core';
import { CloseRounded } from '@material-ui/icons';

export default function Notifications() {
    return (
        <SnackbarProvider
            action={snackbarKey => <SnackbarCloseButton snackbarKey={snackbarKey} />}
            anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        />
    );
}

function SnackbarCloseButton({ snackbarKey }) {
    const { closeSnackbar } = useSnackbar();

    return (
        <IconButton onClick={() => closeSnackbar(snackbarKey)} size='small' color='inherit'>
            <CloseRounded fontSize='small' />
        </IconButton>
    );
}
