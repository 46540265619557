import { useCallback, useMemo, useState } from 'react';

export default function useOpenClose(initiallyOpen = false, { onOpen, onClose } = {}) {
    const [open, setOpen] = useState(initiallyOpen);

    const handleClickOpen = useCallback(() => {
        setOpen(true);
        typeof onOpen === 'function' && onOpen();
    }, [onOpen]);

    const handleClose = useCallback(() => {
        setOpen(false);
        typeof onClose === 'function' && onClose();
    }, [onClose]);

    return useMemo(() => ({
        open,
        handleClickOpen,
        handleClose
    }), [handleClickOpen, handleClose, open])
}
