import { ApolloLink } from '@apollo/client';
import { AUTH_TOKEN_KEY } from '../constants/local-storage-keys';

export const authLink = new ApolloLink((operation, forward) => {
    operation.setContext(({ headers = {} }) => ({
        headers: {
            ...headers,
            authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`,
        }
    }));

    return forward(operation);
});