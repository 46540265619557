export const ROLES = {
    ADMIN: 'Admin',
    GENERAL_USER: 'General User',
    SUPER_USER: 'Super User',
    // CUSTOMER: 'Customer'
    SYSTEM_ADMIN: 'System Admin'
};

const ROLES_FOR_SYSTEM_ADMIN = Object.values(ROLES);

const ROLES_FOR_ADMIN = ROLES_FOR_SYSTEM_ADMIN.filter(x => x !== ROLES.SYSTEM_ADMIN);

const ROLES_FOR_SUPER_USER = ROLES_FOR_ADMIN.filter(x => x !== ROLES.ADMIN);

export const ALLOWED_ROLES = {
    [ROLES.SYSTEM_ADMIN]: ROLES_FOR_SYSTEM_ADMIN,
    [ROLES.ADMIN]: ROLES_FOR_ADMIN,
    [ROLES.SUPER_USER]: ROLES_FOR_SUPER_USER,
}