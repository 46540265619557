import React, { } from 'react';
import { CircularProgress, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import CenterBox from '../common/CenterBox';

export default function CircleButton({ label, wait = false, onClick }) {

    const classes = useStyles();

    return (
        <CenterBox minHeight={200} margin='0 auto' flex='0 0'>
            {!wait ?
                <>
                    <span className={clsx(classes.size, classes.circle, classes.circle1)}></span>
                    <span className={clsx(classes.size, classes.circle, classes.circle2)}></span>
                    <CenterBox
                        className={clsx(classes.size, classes.button)}
                        component='button'
                        tabIndex={-1}
                        onClick={onClick}
                    >
                        {label}
                    </CenterBox>
                </>
                : <CircularProgress />
            }
        </CenterBox>
    )
}

const START = {
    light: '#CDCDCD',
    dark: '#222'
}
const END = {
    light: '#FFF',
    dark: '#303030'
}
const SIZE = '5rem';

const useStyles = makeStyles(({ palette }) => ({
    size: {
        width: SIZE,
        height: SIZE,
        borderRadius: '50%',
        position: 'absolute',
    },
    button: {
        color: palette.text.secondary,
        zIndex: 2,
        fontWeight: 600,
        textTransform: 'uppercase',
        cursor: 'pointer',
        border: 'none',
        outline: 'none',
        background: `linear-gradient(to top left, ${START[palette.type]} 0%, ${END[palette.type]} 100%)`,
        boxShadow: `0.3rem 0.3rem 0.6rem ${START[palette.type]}, -0.2rem -0.2rem 0.5rem ${END[palette.type]}`,
        '&:active': {
            background: `linear-gradient(to bottom right, ${START[palette.type]} 0%, ${END[palette.type]} 100%)`,
            boxShadow: 'none'
        }
    },
    circle: {
        filter: 'blur(1px)',
        zIndex: 1,
        boxShadow: `0.4rem 0.4rem 0.8rem ${START[palette.type]}, -0.4rem -0.4rem 0.8rem ${END[palette.type]}`
    },
    circle1: {
        background: `linear-gradient(to bottom right, ${START[palette.type]} 0%, ${END[palette.type]} 100%)`,
        animation: '$waves 4s linear infinite'
    },
    circle2: {
        animation: '$waves 4s linear 2s infinite'
    },
    '@keyframes waves': {
        '0%': {
            transform: 'scale(1)',
            opacity: 1
        },
        '50%': {
            opacity: 1
        },
        '100%': {
            transform: 'scale(2.6)',
            opacity: 0
        }
    }
}))