import React from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { CancelRounded, CheckCircleRounded } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';

export default function PasswordValidations({ hasValue, errors }) {

    const { t } = useTranslation();

    return (
        <Box mx={1} my={3}>
            <PasswordValidation hasValue={hasValue} error={errors.length} message={t('ChangePassword.Length')} />
            <PasswordValidation hasValue={hasValue} error={errors.upperCase} message={t('ChangePassword.AtLeastOneUpperCase')} />
            <PasswordValidation hasValue={hasValue} error={errors.lowerCase} message={t('ChangePassword.AtLeastOneLowerCase')} />
            <PasswordValidation hasValue={hasValue} error={errors.numeric} message={t('ChangePassword.AtLeastOneDigit')} />
            <PasswordValidation hasValue={hasValue} error={errors.specialChar} message={t('ChangePassword.OneSpecialCharacter')} />
        </Box>
    )
}

function PasswordValidation({ hasValue, error, message }) {

    const classes = useStyles();

    return (
        <div className={classes.root}>
            {error
                ? <CancelRounded fontSize='small' className={clsx(classes.invalid, classes.icon)} />
                : <CheckCircleRounded fontSize='small' className={clsx(hasValue && classes.valid, classes.icon)} />
            }
            <Typography color='textSecondary' variant='body2'>{message}</Typography>
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: theme.spacing(.5),
    },
    icon: {
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(.5),
    },
    valid: {
        color: '#45aa45'
    },
    invalid: {
        color: '#f44467'
    }
}))