import React from 'react';
import { Collapse, ListItem, ListItemAvatar, ListItemText } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import ContainerScanResultAvatar from './ContainerScanResultAvatar';

const ContainerScanResultItem = ({ asset }) => {

    const { t } = useTranslation();

    return (
        <Collapse in appear>
            <ListItem divider>
                <ListItemAvatar>
                    <ContainerScanResultAvatar status={asset.containerScanStatus} />
                </ListItemAvatar>
                <ListItemText
                    primary={asset.id ? asset.name || '-' : t('Scan.NotFound')}
                    secondary={asset.tagNumber || '-'}
                />
            </ListItem>
        </Collapse>
    )
}

export default ContainerScanResultItem;