import { useCallback } from 'react';

export default function useScrollNearBottom(callback, distanceToBottom = 150) {

    const handleScroll = useCallback(({ target }) => {

        const trigger = target.scrollHeight - target.scrollTop - target.clientHeight < distanceToBottom;

        if (trigger) {

            if (callback._tId) return;

            callback._tId = setTimeout(() => {
                callback._tId = null;
            }, 200);

            callback();
        }

    }, [callback, distanceToBottom])

    return [handleScroll];
}