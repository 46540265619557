import React, { forwardRef } from 'react';
import { Typography, makeStyles } from '@material-ui/core';
import clsx from 'clsx';

import { COLORS, SCAN_STATUS } from '../../constants/scan-status';

const Legend = forwardRef(({ children, status, className, ...rest }, ref) => {

    const classes = useStyles();

    return (
        <Typography
            variant='caption'
            color='textSecondary'
            className={clsx(className, classes.root, classes[status])}
            {...rest}
            ref={ref}
        >
            {children}
        </Typography>
    )
})

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(0, 0.5),
        borderLeftWidth: 3,
        borderLeftStyle: 'solid',
        borderRadius: '0 4px 4px 0'
    },
    [SCAN_STATUS.MOVED]: {
        background: `${COLORS[SCAN_STATUS.MOVED]}11`,
        borderLeftColor: COLORS[SCAN_STATUS.MOVED]
    },
    [SCAN_STATUS.NEW]: {
        background: `${COLORS[SCAN_STATUS.NEW]}11`,
        borderLeftColor: COLORS[SCAN_STATUS.NEW]
    },
    [SCAN_STATUS.FOUND]: {
        background: `${COLORS[SCAN_STATUS.FOUND]}11`,
        borderLeftColor: COLORS[SCAN_STATUS.FOUND]
    },
    [SCAN_STATUS.MISSING]: {
        background: `${COLORS[SCAN_STATUS.MISSING]}11`,
        borderLeftColor: COLORS[SCAN_STATUS.MISSING]
    }
}))

export default Legend;