import React, { useCallback, useEffect } from 'react';
import { Collapse, IconButton, ListItem, ListItemText, Tooltip } from '@material-ui/core';
import { Refresh } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import {
    ADD_SCAN,
    ADD_SCAN_FOLDER_CONTAINER,
    ADD_SCAN_ASSET_CONTAINER
} from '../../graphql/mutations/scan';
import { SCAN_CONTAINER } from '../common/scan-container-type';

export default function ScannedTag({
    tag = '',
    sessionId,
    onSaved,
    hideAfterSave = false,
    hiddenIfUnknown = false,
    containerType
}) {
    const { t } = useTranslation();
    const [addScan, { loading, data, error, called }] = useMutation(
        containerType === SCAN_CONTAINER.FOLDER
            ? ADD_SCAN_FOLDER_CONTAINER
            : containerType === SCAN_CONTAINER.ASSET
            ? ADD_SCAN_ASSET_CONTAINER
            : ADD_SCAN
    );
    const scan = data?.addScan || {};

    const addScanEntry = useCallback(async () => {
        try {
            var res = await addScan({
                variables: {
                    scan: {
                        scanSessionId: sessionId,
                        tagNumber: tag
                    }
                }
            });

            if (
                typeof onSaved === 'function' &&
                (res.data.addScan.asset?.id || !res.data.addScan.invalidTagPattern)
            ) {
                onSaved(res.data.addScan.asset || { tagNumber: tag });
            }
        } catch {}
    }, [addScan, onSaved, sessionId, tag]);

    useEffect(() => {
        if (!called) {
            addScanEntry();
        }
    }, [addScanEntry, called]);

    let isUnknownTag = false;
    let primaryText = '';
    if (loading) {
        primaryText = <Skeleton component='p' width={150} />;
    } else if (error) {
        primaryText = t('Scan.Failed');
    } else if (scan.asset?.id) {
        primaryText = scan.asset.name;
    } else if (scan.invalidTagPattern) {
        primaryText = t('Scan.InvalidPattern');
        isUnknownTag = true;
    } else {
        primaryText = t('Scan.NotFound');
        isUnknownTag = true;
    }

    const hide =
        (hideAfterSave && scan.id && (scan.asset?.id || !scan.invalidTagPattern)) ||
        (hiddenIfUnknown && isUnknownTag);

    return (
        <Collapse in={!hide} appear>
            <ListItem divider>
                <ListItemText primary={primaryText} secondary={tag} />
                {!!error && (
                    <Tooltip title={t('Scan.Retry')}>
                        <IconButton onClick={addScanEntry}>
                            <Refresh />
                        </IconButton>
                    </Tooltip>
                )}
            </ListItem>
        </Collapse>
    );
}
