const SCAN_STATUS = {
    MISSING: 'MISSING',
    MOVED: 'MOVED',
    NEW: 'NEW',
    FOUND: 'FOUND',
}

const SCAN_STATUS_ORDER = {
    [SCAN_STATUS.MISSING]: 1,
    [SCAN_STATUS.MOVED]: 2,
    [SCAN_STATUS.NEW]: 3,
    [SCAN_STATUS.FOUND]: 4,
}

const SCAN_STATUS_LETTER = {
    [SCAN_STATUS.MISSING]: 'Mi',
    [SCAN_STATUS.MOVED]: 'Mo',
    [SCAN_STATUS.NEW]: 'N',
    [SCAN_STATUS.FOUND]: 'F',
}

const COLORS = {
    [SCAN_STATUS.MOVED]: '#800080',
    [SCAN_STATUS.FOUND]: '#459845',
    [SCAN_STATUS.NEW]: '#ee9400',
    [SCAN_STATUS.MISSING]: '#e8194a'
}

export {
    SCAN_STATUS,
    SCAN_STATUS_ORDER,
    SCAN_STATUS_LETTER,
    COLORS
}