import React from 'react';
import { NetworkStatus, useQuery } from '@apollo/client';
import FormikSelectAsync from '../common/FormikSelectAsync';
import { TIME_ZONES } from '../../graphql/queries';

export default function SelectTimeZone(props) {

    const { data, loading, refetch, networkStatus } = useQuery(TIME_ZONES);
    const hasError = !loading && networkStatus === NetworkStatus.error;
    const options = data?.timeZones?.map(x => ({ id: x.id, name: x.displayName })) || [];

    const retry = e => {
        e.preventDefault();
        e.stopPropagation();
        refetch();
    }

    return (
        <FormikSelectAsync
            options={options}
            retry={retry}
            hasError={hasError}
            loading={loading}
            {...props}
        />
    )
}