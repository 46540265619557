import { Button, CircularProgress } from '@material-ui/core';
import React from 'react';

export default function SubmitButton({ isSubmitting, ...rest }) {
    return (
        <Button
            variant='contained'
            color='primary'
            type='submit'
            fullWidth
            disabled={isSubmitting}
            startIcon={isSubmitting ? <CircularProgress size={16} /> : null}
            {...rest}
        />
    );
}
