import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useAuth as useOidcAuth } from 'react-oidc-context';
import CenterBox from '../common/CenterBox';
import SplashScreen from '../bootstrap/SplashScreen';
import ErrorWithRetry from '../common/ErrorWithRetry';

export default function AuthorizeWithOidcToken() {
    const { search } = useLocation();
    const { isAuthenticated, error } = useOidcAuth();
    const history = useHistory();

    const query = new URLSearchParams(search);
    const errorFromQuery = query.get('error');

    useEffect(() => {
        if (isAuthenticated) {
            history.replace('/login');
        }
    }, [history, isAuthenticated]);

    return error || errorFromQuery ? (
        <CenterBox height='100%' flexDirection='column'>
            <ErrorWithRetry onClick={() => history.replace('/login')} />
        </CenterBox>
    ) : (
        <SplashScreen />
    );
}
