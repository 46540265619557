import { Typography, makeStyles, Box } from '@material-ui/core';
import clsx from 'clsx';
import useHorizontal from './use-horizontal';

const useStyles = makeStyles(theme => ({
    root: {
        flex: 1,
        flexShrink: 0,
        flexBasis: 200,
        margin: theme.spacing(1.5),
        display: 'flex',
        flexDirection: 'column'
    },
    label: {},
    field: {
        flex: 1
    },
    fullSpan: {
        flex: 1
    },
    horizontal: {
        gap: 10,
        flexBasis: 400,
        flexDirection: 'row',
        margin: theme.spacing(2, 0),
        '& $label': {
            paddingTop: theme.spacing(1),
            textAlign: 'right',
            flex: 1
        },
        '& $field': {
            flex: 2
        },
        '& $fullSpan': {
            flex: 5
        }
    },
    noWrap: {
        flexBasis: 200,
        display: 'block',
        '& $label': {
            display: 'inline-block'
        },
        '& $field': {
            display: 'inline-block'
        },
        '& $fullSpan': {
            display: 'inline-block'
        }
    },
    emptyField: {
        marginTop: 0,
        marginBottom: 0
    }
}));

export default function FormControl({
    label,
    fullSpan = false,
    component = 'label',
    noWrap = false,
    disabled,
    children
}) {
    const classes = useStyles();
    const horizontal = useHorizontal();

    return (
        <Box
            component={component}
            className={clsx(
                classes.root,
                horizontal && classes.horizontal,
                !horizontal && noWrap && classes.noWrap
            )}
        >
            <Typography
                variant='body2'
                className={classes.label}
                color={disabled ? 'textSecondary' : 'textPrimary'}
            >
                {label}
            </Typography>
            <Box className={fullSpan ? classes.fullSpan : classes.field}>{children}</Box>
        </Box>
    );
}

export const EmptyField = () => {
    const classes = useStyles();
    const horizontal = useHorizontal();
    return (
        <div
            className={clsx(classes.root, classes.emptyField, horizontal && classes.horizontal)}
        ></div>
    );
};
