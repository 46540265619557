import { Route, Switch } from 'react-router-dom';
import { ProvideAuth } from './components/auth/use-auth';
import LoginAuth from './components/auth/LoginAuth';
import Logout from './components/auth/Logout';
import PrivateRoute from './components/auth/PrivateRoute';
import NoAuthRoute from './components/auth/NoAuthRoute';
import CreateNewAccount from './components/auth/CreateNewAccount';
import ThankYou from './components/auth/ThankYou';
import Bootstrap from './components/bootstrap/Bootstrap';
import ResetPassword from './components/auth/ResetPassword';
import NewPassword from './components/auth/NewPassword';
import AuthorizeWithOidcToken from './components/auth/AuthorizeWithOidcToken';
import { useConfig } from './components/bootstrap/ConfigProvider';

export default function Router() {
    const { isOidcEnabled, oidc } = useConfig();

    return (
        <ProvideAuth>
            <Switch>
                <NoAuthRoute path='/CreateNewAccount'>
                    <CreateNewAccount />
                </NoAuthRoute>
                <NoAuthRoute path='/ResetPassword'>
                    <ResetPassword />
                </NoAuthRoute>
                <NoAuthRoute path='/NewPassword'>
                    <NewPassword />
                </NoAuthRoute>
                <NoAuthRoute path='/ThankYou'>
                    <ThankYou />
                </NoAuthRoute>
                <NoAuthRoute path='/login'>
                    <LoginAuth />
                </NoAuthRoute>
                <Route path='/logout' component={Logout} />
                {isOidcEnabled && (
                    <Route
                        path={oidc.redirectUri || '/signin-oidc'}
                        component={AuthorizeWithOidcToken}
                    />
                )}
                <PrivateRoute path='/'>
                    <Bootstrap />
                </PrivateRoute>
            </Switch>
        </ProvideAuth>
    );
}
