import React, { useState, useContext, createContext } from 'react';
import { useQuery } from '@apollo/client';

import authApi from '../../networking/Auth';
import { AUTH_TOKEN_KEY } from '../../constants/local-storage-keys';
import { MY_PROFILE } from '../../graphql/queries';

const authContext = createContext();

// Provider component that wraps the app and makes auth object
// available to any child component that calls useAuth().
export function ProvideAuth({ children }) {
    const auth = useProvideAuth();

    return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

// Provider hook that creates auth object and handles state
function useProvideAuth() {
    const [isAuthenticated, setAuthenticated] = useState(!!localStorage.getItem(AUTH_TOKEN_KEY));

    const userQuery = useQuery(MY_PROFILE, { skip: !isAuthenticated });

    const signIn = async (email, password) => {
        const response = await authApi.signInWithEmailAndPassword(email, password);
        return checkToken(response);
    };

    const signOut = () => {
        localStorage.removeItem(AUTH_TOKEN_KEY);
        setAuthenticated(false);
    };

    const signInWithADToken = async accessToken => {
        const response = await authApi.signInWithADToken(accessToken);
        return checkToken(response);
    };

    const signInWithOidcToken = async idToken => {
        const response = await authApi.signInWithOidcToken(idToken);
        return checkToken(response);
    };

    const checkToken = response => {
        if (response && response.token) {
            localStorage.setItem(AUTH_TOKEN_KEY, response.token);
            setAuthenticated(true);
            return true;
        }

        setAuthenticated(false);
        return false;
    };

    const sendPasswordResetEmail = authApi.sendPasswordResetEmail;

    const confirmPasswordReset = authApi.confirmPasswordReset;

    const createNewAccount = authApi.createNewAccount;

    const changePassword = authApi.changePassword;

    // Return the user object and auth methods
    return {
        isAuthenticated,
        userQuery,
        user: userQuery.data?.myProfile || {},
        signIn,
        signOut,
        signInWithADToken,
        signInWithOidcToken,
        sendPasswordResetEmail,
        confirmPasswordReset,
        createNewAccount,
        changePassword
    };
}

// Hook for child components to get the auth object and re-render when it changes.
export const useAuth = () => useContext(authContext);
