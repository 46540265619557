import { useEffect } from 'react';
import { useConfig } from './ConfigProvider';

const SCRIPT_ID = 'usetifulScript';

export default function AddUsetiful() {
    const { usetiful } = useConfig();

    useEffect(() => {
        if (usetiful.enabled && !document.getElementById(SCRIPT_ID)) {
            const head = document.getElementsByTagName('head')[0];
            const script = document.createElement('script');
            script.async = 1;
            script.src = 'https://www.usetiful.com/dist/usetiful.js';
            script.setAttribute('id', SCRIPT_ID);
            script.dataset.token = usetiful.token;
            head.appendChild(script);
        }
    }, [usetiful.enabled, usetiful.token]);

    return null;
}
