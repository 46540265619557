import { Redirect, Route } from 'react-router-dom';
import { useAuth } from './use-auth';

export default function NoAuthRoute({ children, roles = [], ...rest }) {

    const { isAuthenticated } = useAuth();

    return (
        <Route
            {...rest}
            render={() => isAuthenticated
                ? <Redirect to={{ pathname: '/' }} />
                : children
            }
        />
    );
}