import { useState, createContext, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { CONFIG_URL } from '../../constants/common';
import CenterBox from '../common/CenterBox';
import ErrorWithRetry from '../common/ErrorWithRetry';
import SplashScreen from './SplashScreen';

const ConfigContext = createContext();

export function ConfigProvider({ children }) {
    const { t } = useTranslation();
    const [config, setConfig] = useState(null);
    const [error, setError] = useState(false);

    useEffect(() => {
        if (!config) {
            getConfig();
        }
    }, [config]);

    const getConfig = () => {
        setError(false);

        fetch(CONFIG_URL)
            .then(res => res.json())
            .then(setConfig)
            .catch(() => setError(true));
    };

    if (error) {
        return (
            <CenterBox height='100%'>
                <ErrorWithRetry message={t('ConnectionError')} onClick={getConfig} />
            </CenterBox>
        );
    }

    if (!config) {
        return <SplashScreen />;
    }

    return <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>;
}

/**
 * @typedef ConfigType
 * @property {string} apiVersion
 * @property {string} hasPendingDatabaseChanges
 * @property {boolean} isMultiTenancyEnabled
 * @property {boolean} requiresSingleTenantSignUp
 * @property {number} licenseCountNewSignUp
 * @property {number} importMaxFileSize
 * @property {boolean} isAzureADEnabled
 * @property {MsalType} msal
 * @property {boolean} isOidcEnabled
 * @property {OidcType} oidc
 * @property {PasswordOptionsType} passwordOptions
 * @property {AssetFieldsType} assetFields
 * @property {UsetifulType} usetiful
 * @property {AttachmentsType} attachments
 * @property {AssetsGridType} assetsGrid
 */

/**
 * @typedef MsalType
 * @property {string} clientId
 * @property {string} authority
 * @property {string[]} scopes
 */

/**
 * @typedef OidcType
 * @property {string} clientId
 * @property {string} authority
 * @property {string} audience
 * @property {string} scope
 * @property {string} displayName
 * @property {string} redirectUri
 */

/**
 * @typedef PasswordOptionsType
 * @property {boolean} requireDigit
 * @property {boolean} requireLowercase
 * @property {boolean} requireNonAlphanumeric
 * @property {boolean} requireUppercase
 * @property {number} requiredLength
 * @property {number} requiredUniqueChars
 */

/**
 * @typedef RequiredAssetFieldsType
 * @property {boolean} serialNumber
 * @property {boolean} alternateTagNumber
 * @property {boolean} thirdPartyId
 */

/**
 * @typedef UniqueAssetFieldsType
 * @property {boolean} name
 * @property {boolean} serialNumber
 * @property {boolean} alternateTagNumber
 * @property {boolean} thirdPartyId
 */

/**
 * @typedef AssetFieldsType
 * @property {boolean} captureLocation
 * @property {RequiredAssetFieldsType} required
 * @property {UniqueAssetFieldsType} unique
 */

/**
 * @typedef AutoRefreshType
 * @property {boolean} enabled
 * @property {number} intervalInSeconds
 * @property {boolean} userControlEnabled
 */

/**
 * @typedef MassUpdateType
 * @property {boolean} keepValuesInSession
 */

/**
 * @typedef AssetsGridType
 * @property {AutoRefreshType} autoRefresh
 * @property {MassUpdateType} massUpdate
 */

/**
 * @typedef UsetifulType
 * @property {boolean} enabled
 * @property {string} token
 */

/**
 * @typedef ExtensionsType
 * @property {string[]} allowed
 * @property {string[]} blocked
 */

/**
 * @typedef AttachmentsType
 * @property {number} maxFileSizeInBytes
 * @property {ExtensionsType} extensions
 */

/**
 * Provides configurations for the UI app
 * @returns {ConfigType}
 */
export const useConfig = () => useContext(ConfigContext);
