import React, { useEffect, useState, useCallback } from 'react';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import { useTranslation } from 'react-i18next';

import SplashScreen from '../bootstrap/SplashScreen';
import { useAuth } from './use-auth';
import ErrorWithRetry from '../common/ErrorWithRetry';
import { useConfig } from '../bootstrap/ConfigProvider';
import CenterBox from '../common/CenterBox';
import SwitchAuthLink from './SwitchAuthLink';

export default function LoginWithAzureToken() {

    const { t } = useTranslation();
    const [error, setError] = useState(false);
    const { instance, accounts } = useMsal();
    const isADAuthenticated = useIsAuthenticated();
    const { signInWithADToken } = useAuth();
    const { msal } = useConfig();

    const login = useCallback(
        () => {
            setError(false);

            instance.acquireTokenSilent({
                scopes: msal?.scopes,
                account: accounts[0]
            }).then(async (response) => {
                const success = await signInWithADToken(response.accessToken);
                setError(!success)
            }).catch(() => {
                setError(true);
            })
        },
        [accounts, instance, msal?.scopes, signInWithADToken]
    );

    useEffect(
        () => {
            if (isADAuthenticated) {
                login();
            }
        },
        [isADAuthenticated, login]
    );

    return error ? (
        <CenterBox height='100%' flexDirection='column'>
            <ErrorWithRetry onClick={login} />
            <SwitchAuthLink
                to='/logout'
                linkLabel={t('BackToLogin')}
            />
        </CenterBox>
    ) : <SplashScreen />;
}