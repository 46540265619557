import React, { useMemo } from 'react';
import { Button, CircularProgress, FormGroup } from '@material-ui/core';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';

import { UPDATE_PROFILE } from '../../graphql/mutations/user';
import FormikContainer from '../common/FormikContainer';
import SubmitErrorHelper from '../common/SubmitErrorHelper';
import { useAuth } from '../auth/use-auth';
import ReadOnlyValue from '../common/ReadOnlyValue';
import SelectTimeZone from './SelectTimeZone';
import SubmitButtonsContainer from '../common/SubmitButtonsContainer';
import { resetStore } from '../../graphql/reset-store';

export default function UserProfile({ onCancel, onSuccess }) {
    const { t } = useTranslation();
    const { user } = useAuth();

    const [updateProfile] = useMutation(UPDATE_PROFILE, { update: resetStore });

    const handleSubmit = async (values, { setErrors }) => {
        try {
            await updateProfile({ variables: { user: getUserEntity(values) } });

            onSuccess();
        } catch (ex) {
            setErrors({ submitError: t('UnknownError') });
        }
    };

    const initialValues = useMemo(() => getFormValues(user), [user]);

    return (
        <Formik initialValues={initialValues} onSubmit={handleSubmit}>
            {({ errors, isSubmitting }) => (
                <FormikContainer>
                    <FormGroup row>
                        <ReadOnlyValue label={t('FirstName')} value={user.firstName} />
                        <ReadOnlyValue label={t('LastName')} value={user.lastName} />
                    </FormGroup>
                    <FormGroup row>
                        <ReadOnlyValue label={t('Email')} value={user.email} />
                        <ReadOnlyValue label={t('Username')} value={user.username} />
                    </FormGroup>
                    <FormGroup row>
                        <ReadOnlyValue label={t('Scanner')} value={user.scanner?.name} />
                        <SelectTimeZone
                            name='timeZone'
                            label={t('UserProfile.TimeZone')}
                            disableClearable
                        />
                    </FormGroup>
                    <SubmitErrorHelper>{errors.submitError}</SubmitErrorHelper>
                    <SubmitButtonsContainer>
                        <Button variant='contained' onClick={onCancel}>
                            {t('Cancel')}
                        </Button>
                        <Button
                            type='submit'
                            variant='contained'
                            color='primary'
                            disabled={isSubmitting}
                            startIcon={isSubmitting ? <CircularProgress size={16} /> : null}
                        >
                            {t('Save')}
                        </Button>
                    </SubmitButtonsContainer>
                </FormikContainer>
            )}
        </Formik>
    );
}

UserProfile.propTypes = {
    onCancel: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired
};

const getFormValues = user => ({
    id: user.id,
    timeZone: {
        id: user.timeZone?.id || DEFAULT_TIME_ZONE_ID,
        name: user.timeZone?.displayName || DEFAULT_TIME_ZONE
    }
});

const getUserEntity = user => ({
    id: user.id,
    timeZone: user.timeZone.id
});

const DEFAULT_TIME_ZONE = '(UTC-05:00) Eastern Time (US & Canada)';
const DEFAULT_TIME_ZONE_ID = 'Eastern Standard Time';
