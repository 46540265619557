import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import { useAuth as useOidcAuth } from 'react-oidc-context';

import { useAuth } from './use-auth';
import SplashScreen from '../bootstrap/SplashScreen';

export default function Logout() {
    const history = useHistory();
    const { signOut } = useAuth();

    const { instance } = useMsal();
    const isADAuthenticated = useIsAuthenticated();
    const { isAuthenticated, removeUser } = useOidcAuth();

    useEffect(() => {
        (async () => {
            window.sessionStorage.clear();
            signOut();

            if (isADAuthenticated) {
                instance.logoutPopup({
                    postLogoutRedirectUri: '/',
                    mainWindowRedirectUri: '/'
                });
            }

            if (isAuthenticated) {
                await removeUser();
            }

            history.replace('/login');
        })();
    });

    return <SplashScreen />;
}
