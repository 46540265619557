import { Typography, makeStyles } from '@material-ui/core';
import FormControl from './FormControl';

export default function ReadOnlyValue(props) {
    const { label, value, fullSpan, ...rest } = props;
    const classes = useStyles();

    return (
        <FormControl label={label} fullSpan={fullSpan} component='div'>
            <Typography component='div' variant='body1' className={classes.root} {...rest}>
                {value ?? '-'}
            </Typography>
        </FormControl>
    );
}

const useStyles = makeStyles(() => ({
    root: {
        paddingTop: 6
    }
}));
