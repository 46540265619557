import React from 'react';
import FormikBoundTextField from '../common/FormikBoundTextField';

export default function TextField(props) {

    return (
        <FormikBoundTextField
            variant='outlined'
            size='small'
            {...props}
        />
    )
}