import FormControl from './FormControl';
import FormikBoundTextField from './FormikBoundTextField';

// Context Bound and Styled Common Text Field
export default function FormikTextField(props) {

    const { label, fullSpan, disabled, ...rest } = props;

    return (
        <FormControl label={label} fullSpan={fullSpan} disabled={disabled}>
            <FormikBoundTextField
                variant='outlined'
                size='small'
                autoComplete='off'
                fullWidth
                disabled={disabled}
                {...rest}
            />
        </FormControl>
    );
}