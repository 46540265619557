import React, { useEffect } from 'react';
import { useField } from 'formik';
import { Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import FormControl from './FormControl';
import SelectAsync from './SelectAsync';

export default function FormikSelectAsync(props) {

    const { label, options, selectFirstOption = false, fullSpan = false, ...rest } = props;
    const { t } = useTranslation();
    const [field, meta, helper] = useField(props);

    useEffect(() => {
        if (!field.value?.id && selectFirstOption && options.length > 0) {
            helper.setValue(options[0]);
        }
    }, [field, options, selectFirstOption, helper])

    const selectAsync = <SelectAsync
        options={options}
        value={field.value}
        onBlur={() => helper.setTouched(true)}
        onChange={(_e, value) => helper.setValue(value)}
        error={meta.touched && !!meta.error}
        helperText={meta.touched && meta.error}
        getLimitTagsText={rest.tableFilter
            ? more =>
                <Box fontSize={14} whiteSpace='nowrap' maxWidth={150} overflow='hidden' ml={0.5}>
                    {field.value.length > 1
                        ? t('SelectedItems', { count: more })
                        : typeof rest.getOptionLabel === 'function'
                            ? rest.getOptionLabel(field.value[0])
                            : field.value[0].name || field.value[0]
                    }
                </Box>
            : undefined
        }
        {...rest}
    />

    return !label ? selectAsync : (
        <FormControl label={label} fullSpan={fullSpan}>
            {selectAsync}
        </FormControl>
    )
}