import React, { useCallback, useState } from 'react';
import { Box, Divider, List, Typography, makeStyles, CircularProgress } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { NetworkStatus, useQuery } from '@apollo/client';

import useScanner from './use-scanner';
import ErrorWithRetry from '../common/ErrorWithRetry';
import CenterBox from '../common/CenterBox';
import useScanSession from './use-scan-session';
import { PAGE_SIZE } from '../../constants/common';
import { FIND_CONTAINER_ASSETS } from '../../graphql/queries/scan';
import StopButton from './StopButton';
import useScrollNearBottom from '../common/use-scroll-near-bottom';
import LoadMoreButton from '../card-navigation/LoadMoreButton';
import ScannedTag from './ScannedTag';
import { SCAN_STATUS, SCAN_STATUS_ORDER } from '../../constants/scan-status';
import Legend from './Legend';
import AssetItem from './AssetItem';
import ScanResultItem from './ContainerScanResultItem';
import { SCAN_CONTAINER } from '../common/scan-container-type';
import useScanResults from './ScanResultsProvider';
import { useLocalStorage } from '../common/use-storage';
import HideUnknownTags from './HideUnknownTags';

export default function ContainerScan({ onStop, containerFolderId, containerAssetId }) {
    const { t } = useTranslation();
    const classes = useStyles();

    const [hideUnknownTags, setHideUnknownTags] = useLocalStorage(
        'container-scan-hide-unknown-tags',
        false
    );

    const { loading, data, fetchMore, networkStatus } = useQuery(FIND_CONTAINER_ASSETS, {
        variables: getVariables(containerFolderId, containerAssetId),
        fetchPolicy: 'network-only'
    });

    const hasNextPage = !loading && data?.assets?.pageInfo?.hasNextPage;
    const assets = data?.assets?.edges?.map(x => x.node) || [];
    const hasError = !loading && networkStatus === NetworkStatus.error;
    const isFetchingMore = networkStatus === NetworkStatus.fetchMore;
    const showFetchMore = hasNextPage && !isFetchingMore;
    const isEmpty = !loading && networkStatus !== NetworkStatus.error && assets.length === 0;

    const getNextPage = async () => {
        const { endCursor, hasNextPage } = data?.assets?.pageInfo || {};
        if (!hasNextPage) return;

        await fetchMore({
            variables: getVariables(containerFolderId, containerAssetId, endCursor)
        });
    };

    const retry = async () => {
        await fetchMore({
            variables: getVariables(containerFolderId, containerAssetId)
        });
    };

    const {
        createSession,
        scanSessionId,
        error,
        loading: sessionLoading
    } = useScanSession({ containerFolderId, containerAssetId });

    const { scannedItems, setScannedItems } = useScanResults();

    const [tags, setTags] = useState([]);

    const tagReadHandler = useCallback(
        tag => setTags(s => (s.includes(tag) ? s : [...s, tag])),
        []
    );

    useScanner(tagReadHandler);

    const history = useHistory();

    const handleStop = () => {
        if (typeof onStop === 'function') onStop();
        history.push(`/scanresults/${scanSessionId}`);
    };

    const [handleScroll] = useScrollNearBottom(getNextPage, 250);

    const handleScanSaved = useCallback(
        asset => {
            setScannedItems(s => [
                ...s,
                getAssetWithStatus(asset, containerFolderId, containerAssetId)
            ]);
        },
        [containerFolderId, containerAssetId, setScannedItems]
    );

    const assetsNotScanned = assets.filter(
        x => !scannedItems.some(s => s.tagNumber === x.tagNumber)
    );

    const scannedItemsFiltered = scannedItems.filter(
        x => !hideUnknownTags || x.containerScanStatus !== SCAN_STATUS.NEW
    );

    return (
        <Box
            width='100vw'
            maxWidth={280}
            maxHeight='calc(100vh - 150px)'
            overflow='auto'
            bgcolor='background.default'
            display='flex'
            flexDirection='column'
        >
            {(sessionLoading || error) && (
                <CenterBox height={200}>
                    {error && <ErrorWithRetry onClick={createSession} />}
                    {sessionLoading && <CircularProgress />}
                </CenterBox>
            )}
            {!!scanSessionId && (
                <Box className={classes.container}>
                    <Box px={2}>
                        <Typography variant='body1' align='center' gutterBottom>
                            {hideUnknownTags
                                ? t('Scan.AssetsFound', { count: scannedItemsFiltered.length })
                                : t('Scan.TagsFound', { count: tags.length })}
                        </Typography>
                        <Typography variant='caption' color='textSecondary' gutterBottom>
                            {t('Scan.StopPrompt')}
                        </Typography>
                    </Box>
                    <Divider light />
                    <HideUnknownTags
                        checked={hideUnknownTags}
                        onChange={() => setHideUnknownTags(s => !s)}
                    />
                    <Divider light />
                    <Box
                        px={2}
                        py={1}
                        display='flex'
                        justifyContent='space-between'
                        flexWrap='wrap'
                    >
                        <Typography variant='caption' color='textSecondary'>
                            {t('ContainerScan.Expected', { count: data?.assets?.totalCount })}
                        </Typography>
                        <Legend status={SCAN_STATUS.MOVED}>
                            {t('ContainerScan.Moved', {
                                count: getAssetCountByStatus(scannedItems, SCAN_STATUS.MOVED)
                            })}
                        </Legend>
                        <Legend status={SCAN_STATUS.NEW}>
                            {t('ContainerScan.New', {
                                count: getAssetCountByStatus(scannedItems, SCAN_STATUS.NEW)
                            })}
                        </Legend>
                        <Legend status={SCAN_STATUS.FOUND}>
                            {t('ContainerScan.Found', {
                                count: getAssetCountByStatus(scannedItems, SCAN_STATUS.FOUND)
                            })}
                        </Legend>
                    </Box>
                    <Divider light />

                    <List dense className={classes.list} onScroll={handleScroll}>
                        {assetsNotScanned.map(x => (
                            <AssetItem key={x.id} name={x.name} tagNumber={x.tagNumber || '-'} />
                        ))}
                        {loading && (
                            <CenterBox my={2}>
                                <CircularProgress size={20} />
                            </CenterBox>
                        )}
                        {hasError && <ErrorWithRetry onClick={retry} />}
                        {showFetchMore && <LoadMoreButton onClick={getNextPage} />}
                        {isEmpty && (
                            <Typography
                                align='center'
                                variant='caption'
                                component='p'
                                color='textSecondary'
                            >
                                {t('Asset.Empty')}
                            </Typography>
                        )}

                        {scannedItemsFiltered.sort(compareScanStatus).map(x => (
                            <ScanResultItem key={x.tagNumber} asset={x} />
                        ))}

                        {tags.map(x => (
                            <ScannedTag
                                key={x}
                                tag={x}
                                sessionId={scanSessionId}
                                containerType={
                                    containerFolderId ? SCAN_CONTAINER.FOLDER : SCAN_CONTAINER.ASSET
                                }
                                onSaved={handleScanSaved}
                                hideAfterSave
                                hiddenIfUnknown={hideUnknownTags}
                            />
                        ))}

                        <Box height={40} />
                        <CenterBox position='sticky' bottom={0}>
                            <StopButton onClick={handleStop} />
                        </CenterBox>
                    </List>
                </Box>
            )}
        </Box>
    );
}

const useStyles = makeStyles(({ palette, spacing, shadows }) => ({
    container: {
        paddingTop: spacing(1),
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        background: palette.background.paper,
        boxShadow: shadows[1],
        flex: 1
    },
    list: {
        overflow: 'auto',
        paddingBottom: spacing(1),
        position: 'relative'
    }
}));

const getAssetWithStatus = (asset, containerFolderId, containerAssetId) => {
    var assetWithStatus = { ...asset };

    if (!asset.id) {
        assetWithStatus.containerScanStatus = SCAN_STATUS.NEW;
    } else if (containerFolderId && asset.allFolders.some(x => x.id === containerFolderId)) {
        assetWithStatus.containerScanStatus = SCAN_STATUS.FOUND;
    } else if (
        containerAssetId &&
        (asset.parentAsset?.parentAsset?.id === containerAssetId ||
            asset.parentAsset?.id === containerAssetId ||
            asset.id === containerAssetId
        )
    ) {
        assetWithStatus.containerScanStatus = SCAN_STATUS.FOUND;
    } else {
        assetWithStatus.containerScanStatus = SCAN_STATUS.MOVED;
    }

    return assetWithStatus;
};

const getAssetCountByStatus = (assets = [], status) =>
    assets.filter(x => x.containerScanStatus === status).length;

const getVariables = (containerFolderId, containerAssetId, after = null) => ({
    first: PAGE_SIZE,
    after,
    where: containerFolderId
        ? [
            {
                path: 'folderAssets[folderId]',
                value: containerFolderId
            }
        ]
        : [
            {
                path: 'parentAsset.parentAsset.id',
                value: containerAssetId,
                connector: 'or'
            },
            {
                path: 'parentAsset.id',
                value: containerAssetId,
                connector: 'or'
            },
            {
                path: 'id',
                value: containerAssetId
            }
        ]
});

const compareScanStatus = (a, b) =>
    SCAN_STATUS_ORDER[a.containerScanStatus] - SCAN_STATUS_ORDER[b.containerScanStatus];
