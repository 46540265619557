import { useState, createContext, useContext, useCallback } from 'react';

const ScanContext = createContext();

export function ScanResultsProvider({ children }) {

    const scan = useScanResultsBuilder();

    return (
        <ScanContext.Provider value={scan}>
            {children}
        </ScanContext.Provider>
    );
}

const useScanResultsBuilder = () => {

    const [container, setContainer] = useState({});
    const [scanSessionId, setScanSessionId] = useState();

    const setScanDetails = useCallback(
        ({ scanSessionId, containerFolder, containerAsset }) => {

            setScanSessionId(scanSessionId);

            if (containerFolder) {
                setContainer({ containerFolder })
            }
            else if (containerAsset) {
                setContainer({ containerAsset })
            }
            else {
                setContainer({})
            }

            setScannedItems([]);
        },
        []
    );

    const [scannedItems, setScannedItems] = useState([]);

    const isContainerScan = container.containerFolder?.id || container.containerAsset?.id;

    return {
        isContainerScan,
        scanSessionId,
        container,
        scannedItems,
        setScannedItems,
        setScanDetails,
    };
};

const useScanResults = () => useContext(ScanContext);

export default useScanResults;