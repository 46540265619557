import { ApolloClient, from } from '@apollo/client';
import { authLink } from './auth-link';
import { httpLink } from './http-link';
import { errorLink } from './error-link';
import { getCache as getRelayCache } from './relay-cache';
import { getCache as getSimpleCache } from './simple-cache';

const defaultOptions = {
    query: {
        notifyOnNetworkStatusChange: true
    },
    watchQuery: {
        notifyOnNetworkStatusChange: true
    }
};

const getNewApolloClient = (isRelayCache = false) => new ApolloClient({
    cache: isRelayCache ? getRelayCache() : getSimpleCache(),
    link: from([authLink, errorLink, httpLink]),
    defaultOptions
})

const getNewClient = getNewApolloClient;

const getNewRelayClient = () => getNewApolloClient(true);

const client = getNewRelayClient();

const simpleClient = getNewClient();

export {
    simpleClient
}

export default client;