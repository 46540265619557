import { useCallback, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';

import { ADD_SCAN_SESSION } from '../../graphql/mutations/scan';
import useScanResults from './ScanResultsProvider';

export default function useScanSession({ containerFolderId, containerAssetId } = {}) {

    const history = useHistory();

    const [addScanSession, { loading, error, data }] = useMutation(ADD_SCAN_SESSION);
    const scanSessionId = data?.addScanSession?.id;

    const { setScanDetails } = useScanResults();

    const createSession = useCallback(async () => {

        if (history.location.pathname.startsWith('/scanresults')) {
            history.replace('/assets')
        }

        try {
            const res = await addScanSession({
                variables: {
                    scanSession: {
                        containerFolderId,
                        containerAssetId
                    }
                }
            });

            const session = res?.data?.addScanSession || {};

            setScanDetails({
                scanSessionId: session.id,
                containerFolder: session.containerFolder,
                containerAsset: session.containerAsset
            });
        }
        catch { }
    }, [addScanSession, containerAssetId, containerFolderId, history, setScanDetails])

    useEffect(() => {
        createSession();
    }, [createSession]);

    return { createSession, scanSessionId, error: !!error, loading };
}