import React from 'react';
import { makeStyles, Typography } from '@material-ui/core'

export default function Title(props) {
    const classes = useStyles();

    return (
        <Typography
            variant='body2'
            color='textSecondary'
            className={classes.root}
            {...props}
        />
    );
}

const useStyles = makeStyles(() => ({
    root: {
        textTransform: 'uppercase',
        fontWeight: 400,
        marginBottom: 10
    }
}));