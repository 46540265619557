import React, { useEffect, useState } from 'react';
import { AuthProvider } from 'react-oidc-context';
import { UserManager } from 'oidc-client-ts';
import { useConfig } from '../components/bootstrap/ConfigProvider';
import SplashScreen from '../components/bootstrap/SplashScreen';
import { OIDC_TOKEN_URL } from '../constants/common';

const onSigninCallback = _user =>
    window.history.replaceState({}, document.title, window.location.origin);

const fetchMetadata = async authority => {
    const response = await fetch(`${authority}/.well-known/openid-configuration`);
    if (response.ok) {
        const metadata = await response.json();
        const params = new URLSearchParams({
            token_endpoint: metadata.token_endpoint
        });
        // override token endpoint to make the token request from backend
        metadata.token_endpoint = `${OIDC_TOKEN_URL}?${params}`;
        return metadata;
    } else {
        throw new Error(`Failed to fetch metadata from ${authority}`);
    }
};

const initUserManager = async config => {
    const metadata = await fetchMetadata(config.authority);
    const userManager = new UserManager({
        ...config,
        metadata: metadata
    });

    return userManager;
};

export default function OidcAuthProvider({ children }) {
    const { oidc, isOidcEnabled } = useConfig();
    const [userManager, setUserManager] = useState(null);

    useEffect(() => {
        if (isOidcEnabled) {
            const config = {
                client_id: oidc.clientId,
                authority: oidc.authority,
                scope: oidc.scope || undefined,
                redirect_uri: window.location.origin + (oidc.redirectUri || '/signin-oidc'),
                extraQueryParams: oidc.audience ? { audience: oidc.audience } : undefined,
                onSigninCallback
            };

            initUserManager(config).then(setUserManager);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOidcEnabled]);

    if (isOidcEnabled && !userManager) {
        return <SplashScreen />;
    }

    return <AuthProvider userManager={userManager}>{children}</AuthProvider>;
}
