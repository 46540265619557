import React from 'react';
import { Button, makeStyles } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import { useAuth } from '../auth/use-auth';

const useStyles = makeStyles(theme => ({
    navLink: {
        color: theme.palette.text.primary,
        alignSelf: 'stretch',
        borderRadius: 0,
        padding: theme.spacing(1, 2),
        textTransform: 'none',
        transition: theme.transitions.create(['color'], {
            duration: theme.transitions.duration.shorter
        }),
        '&::after': {
            content: '""',
            position: 'absolute',
            bottom: 0,
            left: 8,
            right: 8,
            height: 3,
            backgroundColor: theme.palette.primary.main,
            borderTopLeftRadius: 1,
            borderTopRightRadius: 1,
            transform: 'scaleX(0)',
            transition: theme.transitions.create(['transform'])
        },
        '&:hover': {
            color: theme.palette.primary.main
        }
    },
    navLinkActive: {
        color: theme.palette.primary.main,
        '&::after': {
            transform: 'scaleX(1)'
        }
    }
}))

export default function NavBarLink({ to, roles = [], children }) {

    const classes = useStyles();
    const { user } = useAuth();

    if (roles.length && !roles.includes(user.roleName)) {
        return null;
    }

    return (
        <Button
            component={NavLink}
            to={to}
            activeClassName={classes.navLinkActive}
            className={classes.navLink}
        >
            {children}
        </Button>
    )
}