import React, { Suspense } from 'react';
import {
    AppBar,
    Avatar,
    Toolbar,
    Tooltip,
    Typography,
    IconButton,
    makeStyles,
    Box,
    Badge
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import ContentRoutes from './ContentRoutes';
import { AV_LOGO } from '../constants/image-paths';
import { ROLES } from '../constants/user-roles';
import NavBarLink from './common/NavBarLink';
import Loader from './common/Loader';
import { useAuth } from './auth/use-auth';
import ScanPopup from './scans/ScanPopup';
import { ScanResultsProvider } from './scans/ScanResultsProvider';
import useOpenClose from './common/use-open-close';
import ProfileDrawer from './profile-drawer/ProfileDrawer';

const useStyles = makeStyles(theme => ({
    root: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        minWidth: 1000
    },
    appBar: {
        boxShadow: '0px 1px 1px 0px rgb(0 0 0 / 10%), 0px 1px 3px 0px rgb(0 0 0 / 25%)'
    },
    toolbar: {
        minHeight: 54,
        height: 54
    },
    logo: {
        width: 32,
        marginRight: 5,
        userSelect: 'none'
    },
    name: {
        fontWeight: 400,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        userSelect: 'none',
        fontVariant: 'small-caps'
    },
    avatar: {
        color: theme.palette.common.white,
        background: theme.palette.info.dark,
        width: 32,
        height: 32,
        fontSize: 14,
        fontWeight: 500
    },
    iconButton: {
        width: 48,
        height: 48
    },
    scanButton: {
        marginRight: 152
    }
}));

export default function LayoutMain() {
    const classes = useStyles();
    const { t } = useTranslation();
    const { user } = useAuth();
    const drawer = useOpenClose();

    const profileChar = (user.firstName || user.username)[0].toUpperCase();
    const appName = user.account.whiteLabelName || 'Asset Vue 3';

    return (
        <ScanResultsProvider>
            <div className={classes.root}>
                <AppBar position='sticky' elevation={0} className={classes.appBar} color='default'>
                    <Toolbar className={classes.toolbar}>
                        <img src={AV_LOGO} className={classes.logo} alt='Asset Vue' />
                        <Tooltip title={appName}>
                            <Typography variant='h6' className={classes.name}>
                                {appName}
                            </Typography>
                        </Tooltip>

                        <Box mx={2} alignSelf='stretch' display='flex'>
                            {/* <NavLink to='/dashboard'>{t('Link.Dashboard')}</NavLink> */}
                            <NavBarLink to='/folders'>{t('Link.Dashboard')}</NavBarLink>
                            <NavBarLink to='/assets'>{t('Link.Assets')}</NavBarLink>
                            <NavBarLink to='/maintenance' roles={[ROLES.ADMIN, ROLES.SYSTEM_ADMIN]}>
                                {t('Link.Maintenance')}
                            </NavBarLink>
                            <NavBarLink
                                to='/users'
                                roles={[ROLES.ADMIN, ROLES.SUPER_USER, ROLES.SYSTEM_ADMIN]}
                            >
                                {t('Link.Users')}
                            </NavBarLink>
                            <NavBarLink to='/reports'>{t('Link.Reports')}</NavBarLink>
                        </Box>

                        <Box marginLeft='auto' whiteSpace='nowrap'>
                            <ScanPopup
                                label={t('Scan.QuickScan')}
                                buttonClass={classes.scanButton}
                            />
                            <Tooltip title={t('Profile')}>
                                <IconButton
                                    size='small'
                                    onClick={drawer.handleClickOpen}
                                    className={classes.iconButton}
                                >
                                    <Badge
                                        color='error'
                                        overlap='circular'
                                        variant={!user.account.name ? 'dot' : undefined}
                                    >
                                        <Avatar className={classes.avatar}>{profileChar}</Avatar>
                                    </Badge>
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </Toolbar>
                </AppBar>

                <ProfileDrawer open={drawer.open} handleClose={drawer.handleClose} />

                <Suspense fallback={<Loader />}>
                    <ContentRoutes />
                </Suspense>
            </div>
        </ScanResultsProvider>
    );
}
