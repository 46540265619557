import React from 'react';
import { makeStyles } from '@material-ui/core';
import { DOT } from '../../constants/common';

const useStyles = makeStyles(() => ({
    root: {
        userSelect: 'none'
    }
}));

export default function DotSeparator() {
    const classes = useStyles();

    return <span className={classes.root}>{DOT}</span>;
}
