import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';

export default function SwitchAuthLink({ message, to, linkLabel }) {

    const classes = useStyles();

    return (
        <Typography align='center' variant='body2' className={classes.root}>
            {message}
            <Link to={to} className={classes.switch} replace>
                {linkLabel}
            </Link>
        </Typography>
    );
}

const useStyles = makeStyles(theme => ({
    root: {
        marginBottom: theme.spacing(1)
    },
    switch: {
        marginLeft: theme.spacing(.5),
        color: theme.palette.primary.main,
        '&:hover': {
            color: theme.palette.primary.main,
            textDecoration: 'underline'
        }
    }
}));