import React, { lazy } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ROLES } from '../constants/user-roles';
import NotFound from './auth/NotFound';
import PrivateRoute from './auth/PrivateRoute';

const Dashboard = lazy(() => import('./dashboard/Dashboard'));
const Assets = lazy(() => import('./search-assets/Assets'));
const Maintenance = lazy(() => import('./maintenance/Maintenance'));
const ManageUsers = lazy(() => import('./user-management/ManageUsers'));
const ScanResultsPage = lazy(() => import('./scans/ScanResultsPage'));
const QuickScanResults = lazy(() => import('./scans/QuickScanResults'));
const AssetRedirect = lazy(() => import('./asset-grid/AssetRedirect'));
const Reports = lazy(() => import('./reports/Reports'));
const Import = lazy(() => import('./asset-import/Imports'));

export default function ContentRoutes() {
    return (
        <Switch>
            <Route exact path='/dashboard'>
                {/* <Dashboard /> */}
                <Redirect to='/folders' />
            </Route>

            <Route path='/folders'>
                <Dashboard />
            </Route>

            <PrivateRoute roles={[ROLES.ADMIN, ROLES.SYSTEM_ADMIN]} path='/maintenance'>
                <Maintenance />
            </PrivateRoute>

            <Route exact path={`/asset/:id${GUID_REGEX}`}>
                <AssetRedirect />
            </Route>
            <Route exact path='/assets'>
                <Assets />
            </Route>
            <Route path='/assets/imports'>
                <Import />
            </Route>
            <Route path='/assets'>
                <Redirect to='/assets' />
            </Route>

            <PrivateRoute roles={[ROLES.ADMIN, ROLES.SUPER_USER, ROLES.SYSTEM_ADMIN]} path='/users'>
                <ManageUsers />
            </PrivateRoute>

            <Route path='/scanresults/:id?'>
                <ScanResultsPage />
            </Route>

            <Route path={`/quickscanresults/:id${GUID_REGEX}`}>
                <QuickScanResults />
            </Route>

            <Route path='/reports'>
                <Reports />
            </Route>

            <Route exact path='/notfound'>
                <NotFound />
            </Route>
            <Route exact path='/'>
                <Redirect to='/dashboard' />
            </Route>
            <Route path='/'>
                <Redirect to='/notfound' />
            </Route>
        </Switch>
    );
}

const GUID_REGEX = '([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})';
