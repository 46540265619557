import React from 'react';
import { useIsAuthenticated } from '@azure/msal-react';
import { useAuth as useOidCtcAuth } from 'react-oidc-context';

import LoginWithAzureToken from './LoginWithAzureToken';
import Login from './Login';
import { useConfig } from '../bootstrap/ConfigProvider';
import LoginWithOidcToken from './LoginWithOidcToken';

export default function LoginAuth() {
    const isADAuthenticated = useIsAuthenticated();
    const { isAzureADEnabled, isOidcEnabled } = useConfig();

    const { isAuthenticated } = useOidCtcAuth();

    if (isAzureADEnabled && isADAuthenticated) {
        return <LoginWithAzureToken />;
    }

    if (isOidcEnabled && isAuthenticated) {
        return <LoginWithOidcToken />;
    }

    return <Login />;
}
