import React from 'react';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { msalConfig } from './authConfig';
import { useConfig } from '../components/bootstrap/ConfigProvider';

let msalInstance = null;

export default function MsalAuthProvider({ children }) {
    const { msal, isAzureADEnabled } = useConfig();

    if (isAzureADEnabled && !msalInstance) {
        const { authority, clientId } = msal;

        msalInstance = new PublicClientApplication(
            msalConfig({
                authority,
                clientId,
                redirectUri: window.location.origin
            })
        );
    }

    return msalInstance ? (
        <MsalProvider instance={msalInstance}>{children}</MsalProvider>
    ) : (
        children
    );
}
