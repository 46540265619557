import React from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import { ErrorOutline } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';

export default function ErrorWithRetry({ message, ...rest }) {
    const { t } = useTranslation();
    return (
        <Box display='flex' flexDirection='column' alignItems='center' m={1} color='text.secondary'>
            <Box p={0.5}>
                <ErrorOutline />
            </Box>
            <Typography variant='body2' gutterBottom>
                {message || t('DefaultError')}
            </Typography>
            <Button {...rest} color='inherit'>
                {t('Retry')}
            </Button>
        </Box>
    );
}
