import React, { } from 'react';
import { Avatar, makeStyles } from '@material-ui/core';
import clsx from 'clsx';

import { COLORS, SCAN_STATUS, SCAN_STATUS_LETTER } from '../../constants/scan-status';

const useStyles = makeStyles(() => ({
    root: {
        height: 30,
        width: 30,
        background: 'black',
        color: 'white',
        fontSize: 16,
    },
    [SCAN_STATUS.MOVED]: {
        background: COLORS[SCAN_STATUS.MOVED],
    },
    [SCAN_STATUS.NEW]: {
        background: COLORS[SCAN_STATUS.NEW],
    },
    [SCAN_STATUS.FOUND]: {
        background: COLORS[SCAN_STATUS.FOUND],
    },
    [SCAN_STATUS.MISSING]: {
        background: COLORS[SCAN_STATUS.MISSING],
    }
}))

const ContainerScanResultAvatar = ({ status }) => {

    const classes = useStyles();

    return (
        <Avatar variant='rounded' className={clsx(classes.root, classes[status])}>
            {SCAN_STATUS_LETTER[status]}
        </Avatar>
    )
}

export default ContainerScanResultAvatar;