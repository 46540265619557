import React from 'react';
import { Dialog, DialogContent } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import DialogTitleWithBack from '../common/DialogTitleWithBack';
import useFullscreenPopup from '../common/use-fullscreen-popup';
import ChangePassword from './ChangePassword';

export default function EditUserPassword({ open, onClose }) {

    const { t } = useTranslation();
    const fullScreen = useFullscreenPopup();

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby='edit-user-password-dialog-title'
            aria-describedby='edit-user-password-dialog-description'
            fullWidth
            fullScreen={fullScreen}
            maxWidth='sm'
        >
            <DialogTitleWithBack
                id='edit-user-password-dialog-title'
                onBackPressed={onClose}
            >
                {t('ChangePassword.ChangeMyPassword')}
            </DialogTitleWithBack>
            <DialogContent id='edit-user-password-dialog-description'>
                <ChangePassword onCancel={onClose} />
            </DialogContent>
        </Dialog >
    )
}