import { onError } from '@apollo/client/link/error';

export const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (networkError?.statusCode === 401) {
        window.location.href = `${process.env.PUBLIC_URL}/logout`;
    }
    if (networkError) console.log(`[Network error]: ${networkError}`);
    if (graphQLErrors) {
        graphQLErrors.map(({ message }) => console.log(`[GraphQL error]: ${message}`));
    }
});