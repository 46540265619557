import { gql } from '@apollo/client';

export const commonAssetFields = `
    id
    name
    serialNumber
    tagNumber
    alternateTagNumber
    description
    thirdPartyId
    updated
    created
    lastScanned
    verificationStatus {
        id
        name
    }
    requiresTagNumber
    assetStatus {
        id
        name
    }
    parentFolder {
        id
        name
        path
    }
    parentAsset {
        id
        name
    }
    model {
        id
        name
        defaultClass
        description
        height
        width
        depth
        numberOfSlots
        productLine
        useRackUnits
        assetType {
            id
            name
        }
        manufacturer {
            id
            name
        }
    }
    customer {
        id
        name
    }
    userDefinedFields {
        number
        type
        name
        value
    }`;

export const ASSETS_SEARCH = gql`query AssetSearch(
    $first: Int!
    $after: String
    $orderBy: [OrderByGraph]
    $where: [WhereExpressionGraph]
) {
    assets(
        first: $first
        after: $after
        orderBy: $orderBy
        where: $where
    ) {
        totalCount
        pageInfo {
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
        }
        edges {
            node {
                ${commonAssetFields}
                leafFolderPath
            }
        }
    }
}`;

export const GET_ASSET_DETAILS = gql`query GetAssetById($id: ID!) {
    asset(id: $id) {
        ${commonAssetFields}
        childAssets {
            id
            name
            verificationStatus {
                id
                name
            }
            childAssets {
                id
                name
                verificationStatus {
                    id
                    name
                }
            }
        }
        dataCenterProperties {
            bottomPositionOrSlot
            leftPosition
            rackPosition
            labelX
            labelY
            isNonDataCenter
            rotate
        }
    }
}`;

export const FIND_ASSETS = gql`
    query FindAllAssets(
        $first: Int!
        $after: String
        $assetClass: String
        $orderBy: [OrderByGraph]
        $where: [WhereExpressionGraph]
    ) {
        assets(
            first: $first
            after: $after
            model_defaultClass: $assetClass
            orderBy: $orderBy
            where: $where
        ) {
            totalCount
            pageInfo {
                hasNextPage
                hasPreviousPage
                startCursor
                endCursor
            }
            edges {
                node {
                    id
                    name
                    serialNumber
                    tagNumber
                    model {
                        id
                        name
                        defaultClass
                    }
                }
            }
        }
    }
`;

export const GET_ASSET_CHILDREN = gql`
    query GetChildrenOfBaseAsset($id: ID!) {
        asset(id: $id) {
            id
            name
            verificationStatus {
                id
                name
            }
            childAssets {
                id
                name
                verificationStatus {
                    id
                    name
                }
                childAssets {
                    id
                    name
                    verificationStatus {
                        id
                        name
                    }
                }
            }
        }
    }
`;

export const GET_ASSET_CHILDREN_COUNT = gql`
    query GetAssetChildrenCount($id: [String]) {
        assets(first: 1, where: [{ path: "parentAssetId", value: $id }]) {
            totalCount
        }
    }
`;

export const getAssetValidationQuery = fields => {
    const validations = fields.reduce((q, x) => (x.value ? (q += validationSection(x)) : q), '');
    const param = validations ? '($id: [String])' : '';

    return gql`query ValidateAsset ${param} {
        myAccount {
            id
            name
            licenseCount
            licenseLimit
            whiteLabelName
            totalFolders
            totalAssets
            totalAssetsInAccount
        }
        ${validations}
    }`;
};

const validationSection = ({ alias, path, value }) => `${alias}: assets(
    first: 1
    where: [
        { path: "${path}", value: "${value}" }
        { path: "id", value: $id, negate: true }
    ]
) {
    totalCount
}
`;

export const GET_ASSET_ANCESTORS = gql`
    query GetAssetAncestors($id: ID!) {
        asset(id: $id) {
            id
            parentAsset {
                id
                name
                parentAsset {
                    id
                    name
                }
            }
            allFolders {
                id
                name
                path
            }
        }
    }
`;

export const FIND_ATTACHMENTS_BY_ASSET = gql`
    query FindAttachmentsByAsset(
        $first: Int!
        $after: String
        $orderBy: [OrderByGraph]
        $where: [WhereExpressionGraph]
    ) {
        assetAttachments(first: $first, after: $after, orderBy: $orderBy, where: $where) {
            totalCount
            pageInfo {
                hasNextPage
                hasPreviousPage
                startCursor
                endCursor
            }
            edges {
                node {
                    id
                    assetId
                    attachment {
                        id
                        filename
                        mimeType
                        created
                        createdBy
                    }
                }
            }
        }
    }
`;
