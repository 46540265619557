import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Form } from 'formik';

export default function FormikContainer(props) {

    const classes = useStyles();

    return <Form className={classes.formContainer} {...props} />
}

const useStyles = makeStyles(() => ({
    formContainer: {
        minWidth: 250
    }
}))