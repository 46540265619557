import { React } from 'react';
import { Button, CircularProgress } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { useHistory } from 'react-router-dom';

import { useAuth } from '../auth/use-auth';
import TextField from '../common/FormikTextField';
import FormikContainer from '../common/FormikContainer';
import SubmitErrorHelper from '../common/SubmitErrorHelper';
import useOpenClose from '../common/use-open-close';
import ConfirmationDialog from '../common/ConfirmationDialog';
import SubmitButtonsContainer from '../common/SubmitButtonsContainer';
import PasswordValidations from '../auth/PasswordValidations';

export default function ChangePassword({ onCancel }) {

    const { t } = useTranslation();

    const { user } = useAuth();

    const history = useHistory();
    const passwordChangedToggle = useOpenClose();

    const { changePassword } = useAuth();

    const validate = values => {
        const errors = {};
        if (!values.currentPassword) {
            errors.currentPassword = t('ChangePassword.CurrentPasswordRequired');
        }

        if (!values.password) {
            errors.password = t('ChangePassword.PasswordRequired');
        }
        else {
            if (values.password.length < 8) {
                errors.length = true;
            }

            if (!/(?=.*[a-z])/.test(values.password)) {
                errors.lowerCase = true;
            }

            if (!/(?=.*[A-Z])/.test(values.password)) {
                errors.upperCase = true;
            }

            if (!/(?=.*[0-9])/.test(values.password)) {
                errors.numeric = true;
            }

            if (!/(?=.[!@#$%^&])/.test(values.password)) {
                errors.specialChar = true;
            }
        }

        if (!values.confirmPassword) {
            errors.confirmPassword = t('ChangePassword.ConfirmPasswordRequired');
        }
        else if (values.confirmPassword !== values.password) {
            errors.confirmPassword = t('ChangePassword.PasswordsDoNotMatch');
        }

        return errors;
    };

    const onSubmit = async (values, { setSubmitting, setErrors }) => {
        try {
            let isPasswordChanged = await changePassword(user.username, values.password, values.currentPassword);
            if (isPasswordChanged) {
                passwordChangedToggle.handleClickOpen();
            } else {
                setErrors({ currentPassword: t('ChangePassword.IncorrectPassword') })
            }
        }
        catch {
            setErrors({ submitError: t('UnknownError') })
        }
        setSubmitting(false);
    }

    const logoutUser = async () => history.push('./Logout');

    return (
        <>
            <Formik
                validate={validate}
                onSubmit={onSubmit}
                initialValues={initialValues}
            >
                {({ values, errors, isSubmitting }) =>
                    <FormikContainer>
                        <TextField name='currentPassword' label={t('ChangePassword.CurrentPassword')} type='password' autoFocus />
                        <TextField name='password' label={t('ChangePassword.NewPassword')} type='password' />
                        <TextField name='confirmPassword' label={t('ChangePassword.ConfirmPassword')} type='password' />

                        <PasswordValidations errors={errors} hasValue={!!values.password} />

                        <SubmitErrorHelper>{errors.submitError}</SubmitErrorHelper>
                        <SubmitButtonsContainer>
                            <Button variant='contained' onClick={onCancel}>
                                {t('Cancel')}
                            </Button>
                            <Button
                                type='submit' variant='contained' color='primary'
                                disabled={isSubmitting}
                                startIcon={isSubmitting ? <CircularProgress size={16} /> : null}
                            >
                                {t('Continue')}
                            </Button>
                        </SubmitButtonsContainer>

                    </FormikContainer>
                }
            </Formik>

            <ConfirmationDialog
                open={passwordChangedToggle.open}
                title={t('ChangePassword.Changed')}
                text={t('ChangePassword.PasswordChanged')}
                onClose={logoutUser}
                onCancel={logoutUser}
            />
        </>

    );
}

const initialValues = {
    currentPassword: '',
    password: '',
    confirmPassword: ''
};