import React, { useEffect } from 'react';
import { Button, Divider, FormHelperText } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { Formik } from 'formik';
import { useMsal } from '@azure/msal-react';
import { useAuth as useOidcAuth } from 'react-oidc-context';

import { useAuth } from './use-auth';
import Layout from './Layout';
import TextField from './TextField';
import AuthForm from './AuthFormContainer';
import SwitchAuthLink from './SwitchAuthLink';
import SubmitButton from './SubmitButton';
import CenterBox from '../common/CenterBox';
import { useConfig } from '../bootstrap/ConfigProvider';
import { clearStore } from '../../graphql/clear-store';

export default function Login() {
    const { t } = useTranslation();

    const history = useHistory();
    const location = useLocation();

    const { signIn } = useAuth();
    const from = location.state?.from ?? { pathname: '/' };

    const { instance } = useMsal();
    const { msal, isAzureADEnabled, isMultiTenancyEnabled, requiresSingleTenantSignUp, isOidcEnabled, oidc } = useConfig();
    const oidcAuth = useOidcAuth();

    useEffect(() => {
        window.sessionStorage.clear();
        clearStore();
    }, []);

    const validate = values => {
        const errors = {};
        if (!values.username) {
            errors.username = t('Login.UsernameRequired');
        } else if (values.username.length > 100) {
            errors.username = t('Login.UsernameCharLimit');
        }

        if (!values.password) {
            errors.password = t('Login.PasswordRequired');
        }

        return errors;
    };

    const onSubmit = async (values, { setSubmitting, setErrors }) => {
        try {
            const isAuthenticated = await signIn(values.username, values.password);
            if (isAuthenticated) return history.replace(from);
            setErrors({ loginError: t('Login.UserPasswordError') });
        } catch {
            setErrors({ loginError: t('UnknownError') });
        }
        setSubmitting(false);
    };

    const handleADLogin = () => instance.loginPopup({ scopes: msal?.scopes }).catch(console.log);

    return (
        <Layout title={t('Login')}>
            <Formik
                validate={validate}
                onSubmit={onSubmit}
                validateOnBlur={false}
                initialValues={initialValues}
            >
                {({ errors, isSubmitting }) => (
                    <AuthForm>
                        <TextField name='username' label={t('Username')} autoFocus />
                        <TextField name='password' label={t('Password')} type='password' />
                        <div>
                            <SubmitButton isSubmitting={isSubmitting}>{t('Login')}</SubmitButton>
                            {!!errors.loginError && (
                                <FormHelperText error>{errors.loginError}</FormHelperText>
                            )}
                        </div>
                    </AuthForm>
                )}
            </Formik>
            {(isMultiTenancyEnabled || requiresSingleTenantSignUp) && (
                <SwitchAuthLink
                    message={t('Login.DontHaveAnAccount')}
                    to='/CreateNewAccount'
                    linkLabel={t('SignUp')}
                />
            )}
            <SwitchAuthLink
                message={t('Login.CantAccessAccount')}
                to='/ResetPassword'
                linkLabel={t('ResetPassword')}
            />
            {(isAzureADEnabled || isOidcEnabled) && <Divider light />}

            {isAzureADEnabled && (
                <CenterBox mt={2} mx={2}>
                    <Button
                        fullWidth
                        variant='outlined'
                        size='small'
                        color='primary'
                        onClick={handleADLogin}
                    >
                        {t('Login.External', { name: 'Microsoft' })}
                    </Button>
                </CenterBox>
            )}

            {isOidcEnabled && (
                <CenterBox mt={2} mx={2}>
                    <Button
                        fullWidth
                        variant='outlined'
                        size='small'
                        color='primary'
                        onClick={oidcAuth.signinRedirect}
                    >
                        {t('Login.External', { name: oidc.displayName || 'Open ID Connect' })}
                    </Button>
                </CenterBox>
            )}
        </Layout>
    );
}

const initialValues = {
    username: '',
    password: ''
};
