import React from 'react';
import { makeStyles } from '@material-ui/core';
import { AV_LOGO } from '../../constants/image-paths';
import CenterBox from '../common/CenterBox';


export default function SplashScreen() {
    return (
        <CenterBox height='100%'>
            <Splash />
        </CenterBox>
    )
}

function Splash() {

    const classes = useStyles();

    return (
        <CenterBox>
            <img className={classes.loader} src={AV_LOGO} alt='Asset Vue' />
        </CenterBox>
    )
}

const useStyles = makeStyles(theme => ({
    loader: {
        position: 'absolute',
        width: 100,
        animation: `$show 1s, $breathing 3s ${theme.transitions.easing.sharp} alternate infinite`,
        userSelect: 'none'
    },
    '@keyframes breathing': {
        '0%': {
            transform: 'scale(0.8)'
        },
        '50%': {
            transform: 'scale(1)'
        },
        '100%': {
            transform: 'scale(0.8)'
        }
    },
    '@keyframes show': {
        '0%': {
            opacity: 0
        },
        '100%': {
            opacity: 1
        }
    }
}))