import React from 'react';
import { Autocomplete } from '@material-ui/lab';
import { CircularProgress, TextField, IconButton, Popper, makeStyles } from '@material-ui/core';
import { Refresh } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import ErrorWithRetry from './ErrorWithRetry';

const useStyles = makeStyles(theme => ({
    retry: {
        position: 'absolute',
        right: 4
    },
    loading: {
        position: 'absolute',
        right: 11
    },
    input: {
        fontSize: 14
    },
    root: {
        paddingTop: '0!important',
        paddingBottom: '0!important',
        paddingLeft: '0!important',
        maxHeight: 25
    }
}));

export default function SelectAsync({
    options,
    loading,
    hasError,
    retry,
    error,
    helperText,
    placeholder,
    tableFilter = false,
    ...rest
}) {

    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <Autocomplete
            options={options}
            getOptionLabel={getOptionLabelDefault}
            getOptionSelected={getOptionSelectedDefault}
            fullWidth
            size='small'
            loading={loading}
            autoHighlight
            openOnFocus={tableFilter}
            openText={t('Open')}
            closeText={t('Close')}
            loadingText={t('Loading')}
            noOptionsText={hasError ? <ErrorWithRetry onMouseDown={retry} /> : t('NoOptions')}
            renderInput={params =>
                <TextField
                    {...params}
                    variant='outlined'
                    size='small'
                    autoComplete='off'
                    error={error}
                    helperText={helperText}
                    placeholder={placeholder}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {loading && <CircularProgress color='inherit' size={20} className={classes.loading} />}
                                {hasError ? (
                                    <div className={classes.retry}>
                                        <IconButton size='small' onClick={retry} title={t('Retry')}>
                                            <Refresh color='inherit' />
                                        </IconButton>
                                    </div>
                                ) : (
                                    params.InputProps.endAdornment
                                )}
                            </>
                        ),
                        classes: tableFilter ? {
                            input: classes.input,
                            root: classes.root
                        } : {}
                    }}
                />
            }
            PopperComponent={tableFilter ? WidePopper : undefined}
            {...rest}
        />
    )
}

const getOptionLabelDefault = x => x.name || '';
const getOptionSelectedDefault = (x, value) => x.id === value?.id;

const WidePopper = (props) => (
    <Popper
        {...props}
        style={{ width: 'fit-content', maxWidth: 400, minWidth: 150, zIndex: 1090 }}
        placement='bottom-start'
    />
)