import { useEffect, useState } from 'react';

export function useLocalStorage(key, initialValue) {
    return useStorage(key, initialValue, window.localStorage);
}

export function useSessionStorage(key, initialValue) {
    return useStorage(key, initialValue, window.sessionStorage);
}

function useStorage(key, initialValue, storage) {
    const [value, setValue] = useState(() => {
        const item = storage.getItem(key);

        if (item === null) {
            storage.setItem(key, JSON.stringify(initialValue));
            return initialValue;
        }

        try {
            return JSON.parse(item);
        } catch {
            return initialValue;
        }
    });

    useEffect(
        () => {
            storage.setItem(key, JSON.stringify(value ?? null));
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [value]
    );

    const remove = key => storage.removeItem(key);

    return [value, setValue, remove];
}
