import React from 'react';
import { CircularProgress, makeStyles } from '@material-ui/core';
import CenterBox from './CenterBox';

export default function Loader() {

    const classes = useStyles();

    return (
        <CenterBox flex={1} className={classes.loader}>
            <CircularProgress />
        </CenterBox>
    )
}

const useStyles = makeStyles(() => ({
    loader: {
        animation: `$show 1s`
    },
    '@keyframes show': {
        '0%': {
            opacity: 0
        },
        '100%': {
            opacity: 1
        }
    }
}))