import { createContext, useContext } from 'react';
import { createTheme, CssBaseline, ThemeProvider as MuiThemeProvider } from '@material-ui/core';

import { THEME_MODE } from './constants/common';
import { THEME_KEY } from './constants/local-storage-keys';
import { useLocalStorage } from './components/common/use-storage';

const lightThemeObject = {
    typography: {
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
    },
    palette: {
        primary: {
            // light: will be calculated from palette.primary.main,
            main: '#169bd5',
            //main: '#22bdef',
            // dark: will be calculated from palette.primary.main,
            //contrastText: "#fff"
        },
        secondary: {
            main: '#1b5598',
            contrastText: '#ffcc00',
        },
        error: {
            main: '#f54266'
        },
        transparentBackground: {
            main: '#8888',
            clearer: '#8884'
        },
        background: {
            default: '#f4f5f7'
        },
        // Used by `getContrastText()` to maximize the contrast between
        // the background and the text.
        contrastThreshold: 3,
        // Used by the functions below to shift a color's luminance by approximately
        // two indexes within its tonal palette.
        // E.g., shift from Red 500 to Red 300 or Red 700.
        tonalOffset: 0.2,
        type: THEME_MODE.LIGHT,
    },
    overrides: {
        MuiCssBaseline: {
            '@global': {
                ':root': {
                    colorScheme: 'light'
                }
            },
        },
        // MuiDialog: {
        //     container: {
        //         backdropFilter: 'blur(2px)'
        //     }
        // },
        MuiBackdrop: {
            root: {
                backdropFilter: 'blur(2px)'
            }
        },
        MuiListItemText: {
            primary: {
                wordBreak: 'break-word'
            },
            secondary: {
                wordBreak: 'break-word'
            }
        },
        MuiListItemAvatar: {
            root: {
                minWidth: 40
            }
        },
        MuiListItemIcon: {
            root: {
                minWidth: 40
            }
        }
    },
};

const darkThemeObject = {
    ...lightThemeObject,
    palette: {
        ...lightThemeObject.palette,
        background: {
            default: '#303030',
            paper: '#3a3c3c'
        },
        type: THEME_MODE.DARK
    },
    overrides: {
        ...lightThemeObject.overrides,
        MuiCssBaseline: {
            '@global': {
                ':root': {
                    colorScheme: 'dark'
                }
            },
        }
    },
}

const themes = {
    [THEME_MODE.LIGHT]: lightThemeObject,
    [THEME_MODE.DARK]: darkThemeObject
}

const themeContext = createContext();

export function ThemeProvider({ children }) {
    const theme = useThemeBuilder();
    let muiTheme = createTheme(theme.themeConfig);
    //muiTheme = responsiveFontSizes(muiTheme);
    return (
        <themeContext.Provider value={theme}>
            <MuiThemeProvider theme={muiTheme}>
                <CssBaseline />
                {children}
            </MuiThemeProvider>
        </themeContext.Provider>
    );
}

const useThemeBuilder = () => {
    const [type, setType] = useLocalStorage(THEME_KEY, THEME_MODE.LIGHT)

    return { type, themeConfig: themes[type], selectTheme: setType };
};

const useTheme = () => useContext(themeContext);

export default useTheme;