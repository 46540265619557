const BASE_URL = process.env.REACT_APP_BASE_URL;
const GRAPH_URL = `${BASE_URL}/graphql`;
const AUTH_URL = `${BASE_URL}/token`;
const AD_AUTH_URL = `${AUTH_URL}/azureAD`;
const OIDC_AUTH_URL = `${AUTH_URL}/oidc`;
const OIDC_TOKEN_URL = `${AUTH_URL}/oidc-token`;
const EXPORT_URL = `${BASE_URL}/export`;
const ACCOUNT_URL = `${BASE_URL}/account`;
const RESET_PASSWORD_URL = `${ACCOUNT_URL}/resetPassword`;
const NEW_PASSWORD_URL = `${ACCOUNT_URL}/newPassword`;
const CHANGE_PASSWORD_URL = `${ACCOUNT_URL}/changePassword`;
const IMPORT_URL = `${BASE_URL}/import`;
const REPORT_URL = `${BASE_URL}/report`;
const CONFIG_URL = `${BASE_URL}/configurations`;
const getAssetAttachmentUploadURL = assetId => `${BASE_URL}/assets/${assetId}/attachments`;
const getAssetAttachmentDownloadURL = (assetId, attachmentId) =>
    `${getAssetAttachmentUploadURL(assetId)}/${attachmentId}/download`;

const UI_VERSION = process.env.REACT_APP_VERSION;

const DOT = '·';
const PAGE_SIZE = 20;
const UDF_COUNT = 200;
const THEME_MODE = {
    LIGHT: 'light',
    DARK: 'dark'
};

const GUID_EMPTY = '00000000-0000-0000-0000-000000000000';

export {
    BASE_URL,
    GRAPH_URL,
    AUTH_URL,
    AD_AUTH_URL,
    OIDC_AUTH_URL,
    OIDC_TOKEN_URL,
    DOT,
    PAGE_SIZE,
    THEME_MODE,
    GUID_EMPTY,
    EXPORT_URL,
    ACCOUNT_URL,
    RESET_PASSWORD_URL,
    CHANGE_PASSWORD_URL,
    IMPORT_URL,
    NEW_PASSWORD_URL,
    UI_VERSION,
    REPORT_URL,
    CONFIG_URL,
    UDF_COUNT,
    getAssetAttachmentUploadURL,
    getAssetAttachmentDownloadURL
};
