import React from 'react';
import { Typography, makeStyles, alpha } from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import clsx from 'clsx';

import Usage from './Usage';
import Title from './Title';
import { useAuth } from '../auth/use-auth';
import { MY_ACCOUNT } from '../../graphql/queries';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(2)
    },
    warning: {
        background: alpha(theme.palette.warning.main, 0.1)
    },
    error: {
        background: alpha(theme.palette.error.main, 0.1)
    },
    email: {
        color: theme.palette.primary.main,
        '&:hover': {
            textDecoration: 'underline'
        }
    }
}));

export default function License() {
    const classes = useStyles();
    const { t } = useTranslation();
    const { user } = useAuth();
    useQuery(MY_ACCOUNT); // to allow refetching asset counts on insert/delete

    const { totalAssetsInAccount: assetCount, licenseCount, licenseLimit } = user.account;
    const isWarning = assetCount >= licenseCount;
    const isError = assetCount >= licenseLimit;
    const percentage = Math.round((assetCount / licenseCount) * 100);

    return (
        <div className={clsx(classes.root, isWarning && classes.warning, isError && classes.error)}>
            <Title>{t('License')}</Title>

            <Typography variant='body2'>
                {t('License.Usage', {
                    count: assetCount,
                    total: licenseCount,
                    percentage
                })}
            </Typography>

            <Usage percentage={percentage} warning={isWarning} error={isError} />
            {isWarning && (
                <Typography variant='body2' color='textSecondary'>
                    {t('License.Exceeded')} {isError ? t('License.Error') : t('License.Warning')}
                    <br />
                    <Trans
                        i18nKey={'License.ContactEmail'}
                        values={{ email: 'support@assetvue.com' }}
                        // eslint-disable-next-line jsx-a11y/anchor-has-content, jsx-a11y/anchor-is-valid
                        components={{ emailLink: <a className={classes.email} /> }}
                    />
                </Typography>
            )}
        </div>
    );
}
