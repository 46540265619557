import React from 'react';
import { FormHelperText, makeStyles } from '@material-ui/core';

export default function SubmitErrorHelper(props) {

    const classes = useStyles();

    return <FormHelperText className={classes.submitError} error {...props} />;
}

const useStyles = makeStyles(theme => ({
    submitError: {
        margin: theme.spacing(0, 0, 1, 2)
    }
}))