import React from 'react';
import { Button, lighten, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

export default function StopButton({ className, ...rest }) {

    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <Button
            size='small'
            tabIndex={-1}
            variant='contained'
            color='primary'
            className={clsx(classes.button, className)}
            {...rest}
        >
            {t('Scan.Stop')}
        </Button>
    )
}

const useStyles = makeStyles(({ palette, spacing }) => ({
    button: {
        bottom: spacing(1),
        left: 0,
        right: 0,
        margin: 'auto',
        width: 100,
        backgroundImage: `linear-gradient(90deg,${palette.primary.main} 0%,${palette.primary.main} 40%,${lighten(palette.primary.main, .3)} 60%,${palette.primary.main} 60%)`,
        backgroundSize: '200% auto',
        animation: '$animate 1.5s linear infinite',
    },
    '@keyframes animate': {
        '0%': { backgroundPosition: '200% 0%' },
        '100%': { backgroundPosition: '0% 0%' },
    },
}))


