import { gql } from '@apollo/client';

export const MY_ACCOUNT = gql`
    query GetMyAccount {
        myAccount {
            id
            name
            licenseCount
            licenseLimit
            whiteLabelName
            totalFolders
            totalAssets
            totalAssetsInAccount
        }
    }
`;

export const MY_PROFILE = gql`
    query GetMyProfile {
        myProfile {
            id
            username
            firstName: firstname
            lastName: lastname
            roleName
            isReadOnly
            email
            requirePasswordChange
            scanner {
                id
                name
            }
            timeZone {
                id
                displayName
            }
            ianaTimeZone
            account {
                id
                name
                licenseCount
                licenseLimit
                whiteLabelName
                totalFolders
                totalAssets
                totalAssetsInAccount
            }
        }
    }
`;

export const TIME_ZONES = gql`
    query GetTimeZones {
        timeZones {
            id
            displayName
        }
    }
`;

export const FIND_FOLDERS = gql`
    query FindFolders($first: Int!, $after: String, $where: [WhereExpressionGraph]) {
        folders(first: $first, after: $after, where: $where) {
            pageInfo {
                hasNextPage
                hasPreviousPage
                startCursor
                endCursor
            }
            edges {
                node {
                    id
                    name
                    path
                }
            }
        }
    }
`;

export const FIND_ALL_CUSTOMERS = gql`
    query FindAllCustomers {
        customers {
            id
            name
        }
    }
`;

export const FIND_ALL_ASSET_STATUSES = gql`
    query FindAllAssetStatuses {
        assetStatuses {
            id
            name
        }
    }
`;

export const FIND_ALL_VERIFICATION_STATUSES = gql`
    query FindAllVerificationStatuses {
        verificationStatuses {
            id
            name
            selectable
        }
    }
`;

export const FIND_FOLDER_COUNT_BY_NAME = gql`
    query FolderCountByName($parentFolderId: [String], $name: [String], $id: [String]) {
        folders(
            first: 1
            where: [
                { path: "name", value: $name }
                { path: "parentFolderId", value: $parentFolderId }
                { path: "id", value: $id, negate: true }
            ]
        ) {
            totalCount
        }
    }
`;

export const GET_FOLDER_CHILDREN_COUNT = gql`
    query GetFolderChildrenCount($id: ID!) {
        folder(id: $id) {
            id
            totalChildFolders
            totalAssets
        }
    }
`;
