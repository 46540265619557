import React from 'react';
import { alpha, makeStyles } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
    root: {
        height: 6,
        background: alpha(theme.palette.success.main, 0.1),
        display: 'flex',
        margin: theme.spacing(1, 0),
        borderRadius: 4,
        overflow: 'hidden'
    },
    main: {
        borderRadius: 3,
        background: theme.palette.success.main
    },
    rest: {
        background: alpha(theme.palette.success.main, 0.1)
    },
    warning: {
        background: alpha(theme.palette.warning.main, 0.1),
        '& $main': {
            background: theme.palette.warning.main
        },
        '& $rest': {
            background: alpha(theme.palette.warning.main, 0.1)
        }
    },
    error: {
        background: alpha(theme.palette.error.main, 0.1),
        '& $main': {
            background: theme.palette.error.main
        },
        '& $rest': {
            background: alpha(theme.palette.error.main, 0.1)
        }
    }
}));

export default function Usage({ percentage = 0, warning = false, error = false }) {
    const classes = useStyles();
    const p = Math.min(percentage, 100);

    return (
        <div className={clsx(classes.root, warning && classes.warning, error && classes.error)}>
            <div className={classes.main} style={{ flex: p }}></div>
            <div className={classes.rest} style={{ flex: 100 - p }}></div>
        </div>
    );
}
