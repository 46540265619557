import React from 'react';
import { Box, Button, Popover, makeStyles, IconButton, Tooltip } from '@material-ui/core';
import { Close, WifiTetheringRounded } from '@material-ui/icons';
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state';
import clsx from 'clsx';

import QuickScan from './QuickScan';
import CheckAuth from '../auth/CheckAuth';
import { ROLES } from '../../constants/user-roles';
import ContainerScan from './ContainerScan';
import { useAuth } from '../auth/use-auth';

export default function ScanPopup({
    label,
    containerFolderId,
    containerAssetId,
    buttonClass,
    iconOnly = false
}) {
    const classes = useStyles();
    const { user } = useAuth();

    return (
        <CheckAuth roles={[ROLES.ADMIN, ROLES.GENERAL_USER, ROLES.SUPER_USER, ROLES.SYSTEM_ADMIN]}>
            {user.scanner?.id && (
                <PopupState variant='popover' popupId='quick-scan'>
                    {popupState => (
                        <>
                            {iconOnly ? (
                                <Tooltip title={label}>
                                    <IconButton {...bindTrigger(popupState)} color='primary'>
                                        <WifiTetheringRounded />
                                    </IconButton>
                                </Tooltip>
                            ) : (
                                <Box display='inline-block' mx={1}>
                                    <Button
                                        variant='contained'
                                        color='secondary'
                                        size='small'
                                        className={clsx(classes.button, buttonClass)}
                                        {...bindTrigger(popupState)}
                                    >
                                        {label}
                                    </Button>
                                </Box>
                            )}

                            <Popover
                                {...bindPopover(popupState)}
                                //anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                                BackdropComponent={Backdrop}
                                disableEscapeKeyDown
                            >
                                {containerAssetId || containerFolderId ? (
                                    <ContainerScan
                                        onStop={popupState.close}
                                        containerFolderId={containerFolderId}
                                        containerAssetId={containerAssetId}
                                    />
                                ) : (
                                    <QuickScan onStop={popupState.close} />
                                )}
                                <IconButton
                                    className={classes.close}
                                    onClick={popupState.close}
                                    tabIndex={-1}
                                >
                                    <Close />
                                </IconButton>
                            </Popover>
                        </>
                    )}
                </PopupState>
            )}
        </CheckAuth>
    );
}

const Backdrop = () => (
    <div
        style={{
            background: '#0008',
            position: 'absolute',
            inset: 0,
            backdropFilter: 'blur(2px)'
        }}
    />
);

const useStyles = makeStyles(theme => ({
    close: {
        color: theme.palette.action.active,
        position: 'absolute',
        right: 0,
        top: 0
    },
    button: {
        width: 135
    }
}));
