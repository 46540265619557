import { Button, makeStyles } from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
    root: {
        display: 'block',
        margin: '0 auto 30px'
    }
}));
export default function LoadMoreButton({ onClick }) {

    const { t } = useTranslation();
    const classes = useStyles();
    return (
        <Button onClick={onClick} className={classes.root}>{t('LoadMore')}</Button>
    )
}
