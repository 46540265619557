import React from 'react';
import { Dialog, DialogContent } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import UserProfile from './UserProfile';
import DialogTitleWithBack from '../common/DialogTitleWithBack';
import useFullscreenPopup from '../common/use-fullscreen-popup';

export default function EditUserProfile({ open, onClose, onSuccess }) {

    const { t } = useTranslation();
    const fullScreen = useFullscreenPopup();

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby='edit-user-profile-dialog-title'
            aria-describedby='edit-user-profile-dialog-description'
            fullWidth
            fullScreen={fullScreen}
            maxWidth='md'
        >
            <DialogTitleWithBack
                id='edit-user-profile-dialog-title'
                onBackPressed={onClose}
            >
                {t('UserProfile.Edit')}
            </DialogTitleWithBack>
            <DialogContent id='edit-user-profile-dialog-description'>
                <UserProfile onCancel={onClose} onSuccess={onSuccess} />
            </DialogContent>
        </Dialog >
    )
}