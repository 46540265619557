import { gql } from '@apollo/client';

export const ADD_SCAN_SESSION = gql`
    mutation AddScanSession($scanSession: ScanSessionInputGraph!) {
        addScanSession(scanSession: $scanSession) {
            id
            created
            containerAsset {
                id
                name
                leafFolderPath
            }
            containerFolder {
                id
                name
                path
            }
        }
    }
`;

const commonFields = `
    id
    tagNumber
    antenna
    scanned
    invalidTagPattern`;

export const ADD_SCAN = gql`
mutation AddScan($scan:ScanInputGraph!) {
    addScan(scan: $scan) {
        ${commonFields}
        asset {
            id
            name
            tagNumber
        }
    }
}`;

export const ADD_SCAN_FOLDER_CONTAINER = gql`
mutation AddScan($scan:ScanInputGraph!) {
    addScan(scan: $scan) {
        ${commonFields}
        asset {
            id
            name
            tagNumber
            allFolders {
                id
            }
        }
    }
}`;

export const ADD_SCAN_ASSET_CONTAINER = gql`
mutation AddScan($scan:ScanInputGraph!) {
    addScan(scan: $scan) {
        ${commonFields}
        asset {
            id
            name
            tagNumber
            parentAsset {
                id
                parentAsset {
                    id
                }
            }
        }
    }
}`;
