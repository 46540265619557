import { gql } from '@apollo/client';
import { commonAssetFields } from './asset';

export const CONTAINER_SCAN_RESULTS =
    gql(`query containerScanResultsBySession($scanSessionId:ID!, $first:Int!, $after:String, $assetClass:String, $orderBy:[OrderByGraph], $where:[WhereExpressionGraph]) {
        assets: containerScanResults(scanSessionId: $scanSessionId, first: $first, after: $after, model_defaultClass: $assetClass, orderBy: $orderBy, where: $where) {
            totalCount
            pageInfo {
                hasNextPage
                hasPreviousPage
                startCursor
                endCursor
            }
            edges {
                node {
                    ${commonAssetFields}
                    leafFolderPath
                }
            }
        }
    }`);

export const FIND_CONTAINER_ASSETS = gql`
    query FindAssetsInContainer($first: Int!, $after: String, $where: [WhereExpressionGraph]) {
        assets(first: $first, after: $after, where: $where) {
            pageInfo {
                hasNextPage
                endCursor
            }
            totalCount
            edges {
                node {
                    id
                    name
                    tagNumber
                }
            }
        }
    }
`;

export const FIND_SCANS_BY_ASSET = gql`
    query FindScans(
        $first: Int!
        $after: String
        $orderBy: [OrderByGraph]
        $where: [WhereExpressionGraph]
    ) {
        scans(first: $first, after: $after, orderBy: $orderBy, where: $where) {
            totalCount
            pageInfo {
                hasNextPage
                hasPreviousPage
                startCursor
                endCursor
            }
            edges {
                node {
                    id
                    tagNumber
                    antenna
                    scanned
                    scannedBy
                    asset {
                        id
                        name
                        tagNumber
                        leafFolderPath
                        parentAsset {
                            id
                            name
                            serialNumber
                            model {
                                id
                                name
                                manufacturer {
                                    id
                                    name
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const FIND_SCANS_WITH_ASSET_DETAILS = gql`
    query FindScansIncludingAssetDetails(
        $first:Int!,
        $after:String, 
        $orderBy:[OrderByGraph],
        $where:[WhereExpressionGraph]
    ) {
        scans(first: $first, after: $after, orderBy: $orderBy, where: $where) {
            totalCount
            pageInfo {
                hasNextPage
                hasPreviousPage
                startCursor
                endCursor
            }
            edges {
                node {
                    id
                    tagNumber
                    antenna
                    scanned
                    scannedBy
                    invalidTagPattern
                    asset {
                        ${commonAssetFields}
                        leafFolderPath
                    }
                }
            }
        }
    }`;
