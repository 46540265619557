import React from 'react';
import { NetworkStatus } from '@apollo/client';

import LayoutMain from '../LayoutMain';
import ErrorWithRetry from '../common/ErrorWithRetry';
import CenterBox from '../common/CenterBox';
import SplashScreen from './SplashScreen';
import { useAuth } from '../auth/use-auth';
import RequirePasswordChange from '../user-profile/RequirePasswordChange';

export default function Bootstrap() {

    const { user, userQuery: { loading, networkStatus, refetch } } = useAuth();

    if (loading) return <SplashScreen />;

    if (networkStatus === NetworkStatus.error) {
        return (
            <CenterBox height='100%'>
                <ErrorWithRetry onClick={() => refetch()} />
            </CenterBox>
        )
    }

    if (user.requirePasswordChange) {
        return <RequirePasswordChange />
    }

    return <LayoutMain />;
}