
import { gql } from '@apollo/client';

const outputUserSchema = `{
    id
    username
    firstname
    lastname
    email
    roleName
    isReadOnly
    isInvited
    isAccepted
    inviteDate
    acceptDate
    requirePasswordChange
    scanner {
        id 
        name
    }
    account {
        id
        name
    }
    timeZone {
        id
        displayName
    }
}`;

export const ADD_USER =
    gql`mutation AddUser($user:UserInputGraph!, $role:String!, $email:String!) {
        addUser(user: $user, role:$role, email:$email) ${outputUserSchema}
    }`

export const SAVE_USER =
    gql`mutation SaveUser($user:UserInputGraph!, $role:String!, $email:String!) {
        saveUser(user: $user, role:$role, email:$email) ${outputUserSchema}
    }`

export const DELETE_USER =
    gql`mutation DeleteUser($id:ID!) {
        deleteUser(id: $id) ${outputUserSchema}
    }`

export const RESEND_INVITE =
    gql`mutation resendInvite($id:ID!) {
        resendInvite(id: $id) ${outputUserSchema}
    }`

export const UPDATE_PROFILE =
    gql`mutation UpdateProfile($user:UpdateProfileInputGraph!) {
        updateProfile(user: $user) ${outputUserSchema}
    }`